import React, { JSX } from 'react';
import ContentLoader from 'react-content-loader';
import './PropertyResultsLoader.scss';

const LOAD_AT_A_TIME = 3;

interface PropertyResultsLoaderProps {
  isDesktop: boolean;
  loadAtATime?: number;
}

export const PropertyResultsLoader: React.FC<PropertyResultsLoaderProps> = ({
  isDesktop,
  loadAtATime = LOAD_AT_A_TIME,
}) => {
  const loaders: JSX.Element[] = [];

  if (isDesktop) {
    for (let i = 0; i < loadAtATime; i++) {
      loaders.push(
        <ContentLoader
          key={`propertyResultsLoader-${i}`}
          width={800}
          viewBox="0 0 538 108"
          className="propertyResultsLoader"
        >
          <rect x="9" y="9" rx="10" ry="10" width="120" height="90" />
          <rect x="140" y="18" rx="5" ry="5" width="280" height="23" />
          <rect x="140" y="45" rx="5" ry="5" width="240" height="23" />
          <rect x="140" y="72" rx="5" ry="5" width="280" height="23" />
        </ContentLoader>
      );
    }
  } else {
    for (let i = 0; i < loadAtATime; i++) {
      loaders.push(
        <ContentLoader
          key={`propertyResultsLoader-${i}`}
          width="105%"
          viewBox="0 0 138 120"
          className="propertyResultsLoader"
        >
          <rect x="3.5" y="3.5" rx="8" ry="10" width="95%" height="50" />
          <rect x="3.5" y="60" rx="2" ry="10" width="55%" height="10" />
          <rect x="3.5" y="75" rx="2" ry="10" width="95%" height="10" />
          <rect x="3.5" y="90" rx="2" ry="10" width="95%" height="10" />
          <rect x="3.5" y="105" rx="2" ry="10" width="55%" height="10" />
        </ContentLoader>
      );
    }
  }

  return <>{loaders}</>;
};
