import { useState, useEffect, useMemo } from 'react';
import {
  AgxColumn,
  AgxHeader,
  AgxRow,
  AgxTextInput,
  AgxCurrency,
  AgxButton,
  AgxLabel,
  AgxDivider,
  useWindowSize,
  Breakpoints,
  AgxSlideUpModal,
} from '@urbanx/agx-ui-components';
import ReactModal from 'react-modal';
import { SaleGoals } from 'Api/Agencies/Types/Agencies';
import { formatNumber } from 'utils/formatNumber';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import './GoalsModal.scss';

const isValidNumber = (value: number | null) => {
  if (value == null) return false;
  return !isNaN(value) && isFinite(value) && value > 0;
};

interface GoalsModalProps {
  saveGoals: (saleGoals: SaleGoals) => void;
  goals: SaleGoals | undefined;
  setIsDialogOpen: (isDialogOpen: boolean) => void;
}

const GoalsModal = ({ saveGoals, goals, setIsDialogOpen }: GoalsModalProps) => {
  const [gciGoal, setGciGoal] = useState(goals?.gciGoal ?? 0);
  const [averageSalePrice, setAverageSalePrice] = useState(
    goals?.averageSalePrice ?? 0
  );
  const [averageCommRate, setAverageCommRate] = useState(
    goals?.averageCommissionRate ?? 0
  );
  const [conversionRate, setConversionRate] = useState(
    goals?.averageConversionRate ?? 0
  );

  useEffect(() => {
    setGciGoal(goals?.gciGoal ?? 0);
    setAverageSalePrice(goals?.averageSalePrice ?? 0);
    setAverageCommRate(goals?.averageCommissionRate ?? 0);
    setConversionRate(goals?.averageConversionRate ?? 0);
  }, [goals]);

  const goalSales = useMemo(() => {
    if (!gciGoal || !averageSalePrice || !averageCommRate) return null;

    return Math.ceil(gciGoal / (averageSalePrice * (averageCommRate / 100)));
  }, [gciGoal, averageSalePrice, averageCommRate]);

  const goalSalesVolume = useMemo(() => {
    if (!averageSalePrice || !goalSales) return null;

    return averageSalePrice * goalSales;
  }, [goalSales, averageSalePrice]);

  const goalListings = useMemo(() => {
    if (!goalSales || !averageCommRate) return null;

    return Math.ceil(goalSales / (conversionRate / 100));
  }, [goalSales, conversionRate]);

  const handleSaveGoalsClick = async () => {
    const saleGoals: SaleGoals = {
      gciGoal: gciGoal,
      averageSalePrice: averageSalePrice,
      averageCommissionRate: averageCommRate,
      averageConversionRate: conversionRate,
    };

    await saveGoals(saleGoals);
    setIsDialogOpen(false);
  };

  const windowSize = useWindowSize();

  const modalContent = (
    <>
      <AgxColumn extraClasses={'goals-modal-style'} fill>
        <div className="modal-close-button">
          <button onClick={() => setIsDialogOpen(false)}>
            <CloseIcon />
          </button>
        </div>
        <AgxColumn extraClasses="goalsUpper">
          <AgxHeader size={3}>Set your Annual Goals</AgxHeader>
          <AgxRow fill>
            <AgxCurrency
              id="gciGoal"
              label="GCI Goal"
              onInputValueChange={({ value }) => {
                setGciGoal(Number(value));
              }}
              required
              defaultValue={gciGoal.toString()}
              stretch
            />
          </AgxRow>
          <AgxRow extraClasses="goal-inputs">
            <AgxCurrency
              id="avgSalePrice"
              label="Average Sale Price"
              onInputValueChange={({ value }) => {
                setAverageSalePrice(Number(value));
              }}
              required
              defaultValue={averageSalePrice.toString()}
              stretch
            />
            <AgxTextInput
              id="avgCommRate"
              label="Average Comm rate"
              onInputValueChange={({ value }) => {
                setAverageCommRate(Number(value));
              }}
              noOptionalLabel
              percent
              defaultValue={averageCommRate.toString()}
              stretch
            />
            <AgxTextInput
              id="conversionRate"
              label="Conversion Rate"
              onInputValueChange={({ value }) => {
                setConversionRate(Number(value));
              }}
              noOptionalLabel
              percent
              noDecimalPoints
              defaultValue={conversionRate.toString()}
              stretch
            />
          </AgxRow>
          <AgxRow extraClasses="values-container" fill spaceBetween>
            <AgxColumn extraClasses="value-item">
              <AgxLabel medium>Sales Volume</AgxLabel>
              <AgxLabel small>
                {isValidNumber(goalSalesVolume) ? (
                  <div className="value">
                    ${formatNumber(goalSalesVolume?.toFixed(0) ?? '')}
                  </div>
                ) : (
                  <div className="value-placeholder"></div>
                )}
              </AgxLabel>
            </AgxColumn>
            <AgxColumn extraClasses="value-item">
              <AgxLabel medium>Listings</AgxLabel>
              <AgxLabel small>
                {isValidNumber(goalListings) ? (
                  <div className="value">
                    {formatNumber(goalListings?.toFixed(0) ?? '')}
                  </div>
                ) : (
                  <div className="value-placeholder"></div>
                )}
              </AgxLabel>
            </AgxColumn>
            <AgxColumn extraClasses="value-item">
              <AgxLabel medium>Sales</AgxLabel>
              <AgxLabel small>
                {isValidNumber(goalSales) ? (
                  <div className="value">
                    {formatNumber(goalSales?.toFixed(0) ?? '')}
                  </div>
                ) : (
                  <div className="value-placeholder"></div>
                )}
              </AgxLabel>
            </AgxColumn>
          </AgxRow>
        </AgxColumn>
        <AgxDivider />
        <AgxRow justifyCenter fill extraClasses="goalsButtons">
          <AgxButton
            id="saveGoals"
            large
            primary
            text="Save Goals"
            onClick={() => {
              handleSaveGoalsClick();
              setIsDialogOpen(false);
            }}
          />
          <AgxButton
            id="cancel"
            hollow
            large
            text="Cancel"
            onClick={() => setIsDialogOpen(false)}
          />
        </AgxRow>
      </AgxColumn>
    </>
  );

  if (windowSize === Breakpoints.Mobile) {
    return (
      <AgxSlideUpModal
        content={modalContent}
        onClose={() => setIsDialogOpen(false)}
      ></AgxSlideUpModal>
    );
  }
  return (
    <ReactModal
      isOpen
      onRequestClose={() => setIsDialogOpen(false)}
      className={{
        base: 'goals-modal',
        afterOpen: 'modal-open',
        beforeClose: 'modal-close',
      }}
      appElement={document.getElementById('root') as HTMLElement}
    >
      {modalContent}
    </ReactModal>
  );
};

export default GoalsModal;
