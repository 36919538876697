import {
  AgxColumn,
  AgxControlledCheckbox,
  AgxDatePicker,
} from '@urbanx/agx-ui-components';
import moment from 'moment';
import { useEffect, useState } from 'react';

interface LaunchDetailsProps {
  id: string;
  onValueChanged: (value: { id: string; value: any }) => void;
  defaultValue: {
    launchDate: string;
    isOffMarket: boolean;
  };
  validate: boolean;
}

const LaunchDetails = ({
  id,
  onValueChanged,
  defaultValue,
  validate,
}: LaunchDetailsProps) => {
  const [details, setDetails] = useState(
    defaultValue ?? {
      launchDate: moment().format('YYYY-MM-DD'),
      isOffMarket: false,
    }
  );

  const { launchDate, isOffMarket } = details;
  useEffect(() => {
    onValueChanged({ id, value: details });
  }, [details]);

  return (
    <AgxColumn largeGap fill>
      <AgxDatePicker
        id="launchDate"
        onValueChanged={({ value }) =>
          setDetails({
            ...details,
            launchDate: value,
          })
        }
        date
        required={!isOffMarket}
        disabled={isOffMarket}
        noOptionalLabel
        label="Launch Date"
        stretch
        defaultValue={launchDate}
        error={
          validate && !isOffMarket && launchDate === ''
            ? 'Enter launch date'
            : undefined
        }
      />
      <AgxControlledCheckbox
        onValueChanged={value =>
          setDetails({
            ...details,
            isOffMarket: value,
          })
        }
        checked={isOffMarket}
        naked
        label="Off market"
      />
    </AgxColumn>
  );
};

export default LaunchDetails;
