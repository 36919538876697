import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  AgxLabel,
  AgxRow,
  AgxColumn,
  AgxInputText,
  AgxBodyText,
  AgxButton,
  Images,
  DocumentTypes,
  UploadedFileType,
  FormType,
} from '@urbanx/agx-ui-components';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import { useAzureAuth } from 'hooks/useAzureAuth';
import {
  deleteFile,
  requestGetFileLink,
  useUploadFile,
} from 'components/ui-components/File/fileApi';
import { formatFileSize } from 'utils/formatFileSize';
import { formatNumber } from 'utils/formatNumber';
import { ReactComponent as LoaderOutline } from './loader-outline.svg';
import { useUploadingFilesReducer, FilePayload } from './UploadingFilesReducer';
import { fileSizeInWords } from 'utils/fileSizeInWords';
import useOutsideAlerter from 'hooks/useOutsideAlerter';
import './FileUpload.scss';
import { openFileInNewTab } from 'utils/openFileInNewTab';
import { Breakpoints, ScreenSize } from 'utils/screen';

interface FileUploadProps {
  id: string;
  title?: string;
  readonly?: boolean;
  extensions: string[];
  fileData: any;
  campaignId: string | undefined;
  documentType: DocumentTypes;
  multiple?: boolean;
  options?: UploadedFileType[];
  onValueChanged: (change: { id: string; value: any }) => void;
  onFileTypeSelected?: (select: string) => void;
  required?: boolean;
  validate?: boolean;
}

//1024 (bytes) * 1024 (kb) * 30 (MB) = 30MB
const maxFileSize = 1024 * 1024 * 30;

const FileUpload = (props: FileUploadProps) => {
  const {
    id,
    title = '',
    readonly = false,
    extensions = [],
    fileData,
    campaignId,
    documentType,
    multiple = false,
    options = [],
    onValueChanged,
    onFileTypeSelected,
    required,
    validate,
  } = props;

  const inputExtensions = useMemo(() => {
    return extensions?.map(extension => `.${extension}`)?.join(',');
  }, [extensions]);

  const fileDataCleaned =
    fileData !== null && Array.isArray(fileData)
      ? fileData
      : fileData !== undefined && fileData != null
        ? [fileData]
        : [];

  const [, getAuthToken] = useAzureAuth();
  const uploadFile = useUploadFile();

  const defaultFileTypeLabel = 'Choose file type';
  const fileTypeOptions = options?.map(option => option);

  const [uploadedFiles, setUploadedFiles] = useState(fileDataCleaned);
  const [removingIndex, setRemovingIndex] = useState<number | null>(null);
  const {
    uploadingFiles,
    updateFileUploadProgress,
    addUploadingFiles,
    removeUploadingFile,
    addFileToUploadingFile,
  } = useUploadingFilesReducer();
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [selectedFileType, setSelectedFileType] =
    useState<string>(defaultFileTypeLabel);
  const [error, setError] = useState<string | null>(null);
  const uploadRef = useRef<HTMLInputElement | null>(null);
  const loaderRef = useRef(null);

  useEffect(() => {
    onValueChanged({ id, value: uploadedFiles });
  }, [uploadedFiles]);

  useEffect(() => {
    if (onFileTypeSelected) onFileTypeSelected(selectedFileType);
  }, [selectedFileType]);

  const moreOptionsRef = useRef(null);
  useOutsideAlerter(moreOptionsRef, onTriggerOutside => {
    if (onTriggerOutside === 'outside') setShowOptions(false);
  });

  const selectedDefaultFileType = selectedFileType === defaultFileTypeLabel;

  const processFileUpload = useCallback(
    async (uploadingFile: FilePayload) => {
      const authToken = await getAuthToken();

      const uploadedFile = await uploadFile(
        authToken as string,
        uploadingFile.formData,
        campaignId as string,
        documentType as unknown as FormType,
        progressEvent => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          updateFileUploadProgress(uploadingFile.id, progress);
        },
        uploadingFile.abortController?.signal
      );

      addFileToUploadingFile(uploadingFile, uploadedFile as File);
    },
    [
      getAuthToken,
      uploadFile,
      campaignId,
      documentType,
      updateFileUploadProgress,
      addFileToUploadingFile,
    ]
  );

  useEffect(() => {
    const filesToStartUploading = uploadingFiles.filter(
      uploadingFile => uploadingFile.progress == null
    );
    const filesToRemove = uploadingFiles.filter(
      uploadingFile => uploadingFile.uploadedFile != null
    );

    if (filesToStartUploading.length > 0) {
      filesToStartUploading.forEach(async uploadingFile => {
        updateFileUploadProgress(uploadingFile.id, 0);
        await processFileUpload(uploadingFile);
      });
    }

    if (filesToRemove.length > 0) {
      setUploadedFiles([
        ...uploadedFiles,
        ...filesToRemove.map(file => file.uploadedFile),
      ]);
      filesToRemove.forEach(file => removeUploadingFile(file));
    }
  }, [uploadingFiles]);

  const isDesktop = ScreenSize() === Breakpoints.Desktop;
  const handleGetFileLink = useCallback(
    async (containerFilePath: string) => {
      const authToken = await getAuthToken();
      const fileLink = await requestGetFileLink(
        authToken as string,
        containerFilePath
      );
      if (fileLink) {
        openFileInNewTab(isDesktop, fileLink);
      }
    },
    [getAuthToken, requestGetFileLink]
  );

  const handleFileUpload = useCallback(
    (e: any) => {
      setError(null);

      const files: File[] = Array.from(e.target.files);

      const invalidFiles = files.map(file => {
        if (
          uploadedFiles.some(
            uploadedFile => uploadedFile.fileName === file.name
          )
        ) {
          setError('Unable to upload duplicate file');
          return true;
        }

        if (file.size != null && file.size > maxFileSize) {
          setError(
            `Unable to upload a file larger than ${fileSizeInWords(
              maxFileSize
            )}`
          );
          return true;
        }

        const hasInvalidExtension =
          extensions.length > 0 &&
          extensions.every(extension => {
            const splits = file.name.split('.');
            return splits[splits.length - 1] !== extension;
          });

        if (hasInvalidExtension) {
          setError(
            `Upload file with .${extensions.join(', .')} extension only`
          );
          return true;
        }

        return false;
      });

      if (invalidFiles.some(invalid => invalid)) return;

      addUploadingFiles(
        files.map(file => {
          const formData = new FormData();

          formData.append('File', file);

          return {
            id: uuidv4(),
            fileName: file.name,
            fileSize: file.size,
            formData: formData,
            abortController: new AbortController(),
            progress: undefined,
          };
        })
      );
    },
    [uploadedFiles, setError, maxFileSize, extensions, addUploadingFiles]
  );

  const handleCancelUpload = useCallback(
    (uploadingFile: FilePayload) => {
      if (uploadingFile) {
        const { abortController } = uploadingFile;
        abortController?.abort();
        removeUploadingFile(uploadingFile);
      }
    },
    [removeUploadingFile]
  );

  const handleDeleteFile = useCallback(
    async (containerFilePath: string) => {
      setRemovingIndex(
        uploadedFiles.findIndex(
          uld => uld.containerFilePath === containerFilePath
        )
      );
      const authToken = await getAuthToken();
      await deleteFile(authToken as string, containerFilePath);
      setUploadedFiles([
        ...uploadedFiles.filter(
          uld => uld.containerFilePath !== containerFilePath
        ),
      ]);
      setRemovingIndex(null);
    },
    [
      setRemovingIndex,
      uploadedFiles,
      getAuthToken,
      deleteFile,
      setUploadedFiles,
    ]
  );
  const clickInput = useCallback(() => {
    if (uploadRef?.current == null) return;
    uploadRef.current.click();
  }, [uploadRef]);

  useEffect(() => {
    if (
      validate &&
      required &&
      uploadedFiles.length === 0 &&
      !loaderRef.current
    ) {
      setError(`  Upload ${title}`);
    }
  }, [validate, uploadedFiles, required, setError, title]);

  return (
    <AgxColumn largeGap fill extraClasses="fileSelector">
      <AgxLabel medium dataTestId={`label-${id}`}>
        {title}
      </AgxLabel>
      {uploadedFiles.map((uploadedFile, index) => {
        const isRemoving = removingIndex === index;

        return (
          <React.Fragment key={`${index}`}>
            {uploadedFile && (
              <div className="uploadedFile">
                <AgxRow
                  centered
                  justifyCenter
                  extraClasses="fileIcon"
                  onClick={e => {
                    if (uploadedFile) {
                      e.preventDefault();
                      handleGetFileLink(uploadedFile.containerFilePath);
                    }
                  }}
                >
                  {!isRemoving && <Images.FileTextOutline />}
                  {isRemoving && <LoaderOutline />}
                </AgxRow>
                <AgxColumn extraClasses="fileDetails">
                  <AgxRow centered mediumGap>
                    <AgxInputText
                      extraClasses="fileName"
                      medium
                      data-testid={`agx-testFileLabel${id}`}
                    >
                      {decodeURIComponent(uploadedFile?.fileName ?? '')}
                    </AgxInputText>
                    <AgxBodyText extraClasses="fileSize" small neutralGrayColor>
                      {formatFileSize(uploadedFile.fileSizeInBytes)}
                    </AgxBodyText>
                  </AgxRow>
                  <div
                    ref={moreOptionsRef}
                    onClick={() => setShowOptions(!showOptions)}
                    className="fileOptionsSelector"
                  >
                    {options.length > 0 && (
                      <AgxRow centered onClick={() => setShowOptions(true)}>
                        <span
                          className={clsx(
                            'agx-selectedValueLabel',
                            selectedDefaultFileType && 'default'
                          )}
                        >
                          {selectedFileType}
                        </span>
                        <Images.Dropdown
                          className={clsx(
                            'agx-dropdownIcon',
                            selectedDefaultFileType && 'default'
                          )}
                        />
                      </AgxRow>
                    )}
                    {showOptions && (
                      <div className="fileOptions">
                        {fileTypeOptions.map((fileTypeOption, index) => (
                          <AgxColumn key={`${fileTypeOption}-${index}`}>
                            <span
                              className="agx-selectedValue"
                              onClick={() => {
                                setSelectedFileType(fileTypeOption);
                                setShowOptions(false);
                              }}
                            >
                              {fileTypeOption}
                            </span>
                          </AgxColumn>
                        ))}
                      </div>
                    )}
                  </div>
                </AgxColumn>
                {!isRemoving && !readonly && (
                  <Images.CrossFill
                    className="removeButton"
                    onClick={e => {
                      e.preventDefault();
                      handleDeleteFile(uploadedFile.containerFilePath);
                    }}
                  />
                )}
              </div>
            )}
          </React.Fragment>
        );
      })}
      {uploadingFiles.map(uploadingFile => {
        return (
          <div
            key={`uploadingFile-${uploadingFile.id}`}
            className="uploadedFile uploading"
          >
            <AgxRow centered justifyCenter extraClasses="fileIcon">
              <LoaderOutline ref={loaderRef} />
            </AgxRow>
            <AgxRow centered mediumGap extraClasses="fileDetails">
              <AgxInputText
                extraClasses="fileName"
                medium
                data-testid={`agx-testFileLabel${id}`}
              >
                {decodeURIComponent(uploadingFile.fileName ?? '')}
              </AgxInputText>
              <AgxBodyText extraClasses="fileSize" small neutralGrayColor>
                {formatFileSize(uploadingFile.fileSize as number)}
              </AgxBodyText>
              <div className="fileProgressIndicator">
                <div
                  className="currentProgress"
                  style={{
                    width: `${uploadingFile.progress}%`,
                  }}
                ></div>
              </div>
              <AgxBodyText extraClasses="uploadProgress" small neutralGrayColor>
                {formatNumber(`${uploadingFile.progress}`)}%
              </AgxBodyText>
            </AgxRow>
            <Images.CrossFill
              onClick={e => {
                e.preventDefault();
                handleCancelUpload(uploadingFile);
              }}
            />
          </div>
        );
      })}
      {readonly === false &&
        (uploadedFiles.length === 0 || multiple) &&
        uploadingFiles.length === 0 && (
          <>
            <input
              accept={inputExtensions}
              ref={uploadRef}
              type="file"
              multiple={multiple}
              onChange={handleFileUpload}
              disabled={removingIndex != null}
            />
            <AgxButton
              hollow
              medium
              dataTestId={`agx-signedFileUploadBtn-${documentType}-${id + uploadedFiles.length}`}
              text="Choose file"
              onClick={clickInput}
              disabled={removingIndex != null}
            />
          </>
        )}
      {error && (
        <AgxRow fill mediumGap>
          <Images.AlertCircle />
          <AgxBodyText small extraClasses={'error'}>
            {error}
          </AgxBodyText>
        </AgxRow>
      )}
    </AgxColumn>
  );
};

export default FileUpload;
