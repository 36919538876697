import {
  PoolDocumentation,
  PoolDocumentationType,
} from '@urbanx/agx-ui-components';
import { useAppSelector } from '../hooks/useAppSelector';

const useListingDocumentsSelector = () => {
  const generatedFormDocuments = useAppSelector(
    state => state.form.generatedFormDocuments
  );

  const poolDocumentation = useAppSelector(
    state => state.form.formValues?.PoolDocumentation
  ) as PoolDocumentation;
  const poolDocs = [];

  if (
    poolDocumentation?.poolDocumentationType ===
      PoolDocumentationType.PoolSafetyCertificate &&
    poolDocumentation?.poolSafetyCertificate?.document
  )
    poolDocs.push(poolDocumentation?.poolSafetyCertificate?.document);

  if (
    poolDocumentation?.poolDocumentationType ===
      PoolDocumentationType.BuildingCertificate &&
    poolDocumentation?.buildingCertificate?.document
  )
    poolDocs.push(poolDocumentation?.buildingCertificate?.document);

  const documents = [...generatedFormDocuments, ...poolDocs];

  return documents ?? [];
};

export default useListingDocumentsSelector;
