import {
  AgxCheckbox,
  AgxLabel,
  AgxRadio,
  AustralianState,
  SaleType,
} from '@urbanx/agx-ui-components';
import React, { useEffect, useState } from 'react';
import AuctionDetails from '../AuctionDetails/AuctionDetails';

interface SaleDetailsProps {
  id: string;
  validate: boolean;
  propertyAddress: {
    state: string;
  };
  onValueChanged: (value: { id: string; value: any }) => void;
  defaultValue: {
    saleType: SaleType;
    auctionDate: string;
    auctionLocation: string;
  };
}

const SaleDetails = ({
  id,
  validate,
  propertyAddress,
  onValueChanged,
  defaultValue,
}: SaleDetailsProps) => {
  const [saleDetailsState, setSaleDetailsState] = useState({
    saleType: defaultValue?.saleType,
    auctionDate: defaultValue?.auctionDate,
    auctionLocation: defaultValue?.auctionLocation,
    addAuctionDetails: defaultValue?.auctionDate != null,
  });

  const { saleType, auctionDate, auctionLocation, addAuctionDetails } =
    saleDetailsState;

  useEffect(() => {
    onValueChanged({
      id,
      value: {
        ...saleDetailsState,
        auctionDate: addAuctionDetails ? auctionDate : null,
        auctionLocation: addAuctionDetails ? auctionLocation : null,
      },
    });
  }, [saleDetailsState]);

  const isNSW = propertyAddress?.state === AustralianState[AustralianState.NSW];

  return (
    <div className="agxRadio">
      <AgxLabel medium>Sale Type</AgxLabel>
      <div className="agxRadio-group">
        <AgxRadio
          id="privateTreaty"
          label="Private Treaty"
          onCheckChanged={() =>
            setSaleDetailsState({
              ...saleDetailsState,
              saleType: SaleType.PrivateTreaty,
            })
          }
          checked={saleType === SaleType.PrivateTreaty}
        />
        <AgxRadio
          id="auction"
          label="Auction"
          onCheckChanged={() =>
            setSaleDetailsState({
              ...saleDetailsState,
              saleType: SaleType.Auction,
            })
          }
          checked={saleType === SaleType.Auction}
          subContent={
            saleType === SaleType.Auction
              ? [
                  <React.Fragment key="addAuctionDetails">
                    <AgxCheckbox
                      id="addAuctionDetails"
                      onValueChanged={({ value }) =>
                        setSaleDetailsState({
                          ...saleDetailsState,
                          addAuctionDetails: value,
                        })
                      }
                      defaultValue={addAuctionDetails}
                      subLabel={
                        isNSW
                          ? 'Add auction details'
                          : 'Add Auction Date and Time'
                      }
                      stretch
                    />
                  </React.Fragment>,
                  <React.Fragment key="details">
                    {addAuctionDetails && (
                      <AuctionDetails
                        id="auctionDetails"
                        validate={validate}
                        required={false}
                        onValueChanged={({
                          value: { auctionDate, location },
                        }: {
                          value: { auctionDate: string; location: string };
                        }) => {
                          setSaleDetailsState({
                            ...saleDetailsState,
                            auctionDate: auctionDate,
                            auctionLocation: location,
                          });
                        }}
                        defaultValue={{
                          auctionDate,
                          location: auctionLocation,
                        }}
                      />
                    )}
                  </React.Fragment>,
                ]
              : undefined
          }
        />
      </div>
    </div>
  );
};

export default SaleDetails;
