import { useEffect } from 'react';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { setAndShowErrorToast } from '../../store/config';
import { LoadingState } from '../../utils/loadingState';
import { agenciesApi } from 'Api/Agencies/agenciesApi';
import { useAzureAuth } from '../../hooks/useAzureAuth';
import { AppDispatch } from '../../store/store';
import { AgencyAgentModel, AgencyInfo } from 'Api/Agencies/Types/Agencies';

interface AgenciesState {
  agencyInfo: {
    loadingState: LoadingState;
    info: AgencyInfo;
  };
  agents: {
    loadingState: LoadingState;
    items: AgencyAgentModel[];
  };
}

const initialState = {
  agencyInfo: {
    loadingState: LoadingState.NotLoaded,
    info: {
      agreements: [0],
    },
  },
  agents: {
    loadingState: LoadingState.NotLoaded,
    items: [],
  },
} satisfies AgenciesState as AgenciesState;

const slice = createSlice({
  name: 'agencies',
  initialState,
  reducers: {
    startLoadingAgencyInfo: state => {
      return {
        ...state,
        agencyInfo: {
          ...initialState.agencyInfo,
          loadingState: LoadingState.Loading,
        },
      };
    },
    agencyInfoLoaded: (state, action: PayloadAction<AgencyInfo>) => {
      const info = action.payload;
      return {
        ...state,
        agencyInfo: {
          info: { ...info },
          loadingState: LoadingState.Loaded,
        },
      };
    },
    errorLoadingAgencyInfo: state => {
      return {
        ...state,
        agencyInfo: {
          ...initialState.agencyInfo,
          loadingState: LoadingState.Failed,
        },
      };
    },
    startLoadingAgents: state => {
      return {
        ...state,
        agents: {
          ...initialState.agents,
          loadingState: LoadingState.Loading,
        },
      };
    },
    agentsLoaded: (state, action: PayloadAction<AgencyAgentModel[]>) => {
      return {
        ...state,
        agents: {
          items: action.payload,
          loadingState: LoadingState.Loaded,
        },
      };
    },
    errorLoadingAgents: state => {
      return {
        ...state,
        agents: {
          ...initialState.agents,
          loadingState: LoadingState.Failed,
        },
      };
    },
  },
});

export default slice.reducer;

const {
  startLoadingAgencyInfo,
  agencyInfoLoaded,
  errorLoadingAgencyInfo,
  startLoadingAgents,
  agentsLoaded,
  errorLoadingAgents,
} = slice.actions;

export const fetchAgents =
  (authToken: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoadingAgents());
      const { data: agents } =
        await agenciesApi(authToken).get<AgencyAgentModel[]>(
          'GetAgentsInAgency'
        );

      dispatch(agentsLoaded(agents));

      return 'success';
    } catch (err: any) {
      console.error(err);
      dispatch(setAndShowErrorToast(err.message));
      dispatch(errorLoadingAgents());
      return err.message;
    }
  };

export const useFetchAgencyInfo = () => {
  const dispatch = useAppDispatch();
  const [userAccount, getAuthToken] = useAzureAuth();
  const agencyInfoLoadingState = useAppSelector(
    state => state.agencies.agencyInfo.loadingState
  );

  const fetchAgencyInfo = async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoadingAgencyInfo());

      const authToken = await getAuthToken();

      if (!authToken) return;

      const { data: agencyInfo } = await agenciesApi(authToken).get<AgencyInfo>(
        'GetAgencyInformationForUser'
      );

      dispatch(agencyInfoLoaded(agencyInfo));

      return 'success';
    } catch (err: any) {
      console.error(err);
      dispatch(setAndShowErrorToast(err.message));
      dispatch(errorLoadingAgencyInfo());
      return err.message;
    }
  };

  useEffect(() => {
    if (agencyInfoLoadingState !== LoadingState.NotLoaded || !userAccount)
      return;
    fetchAgencyInfo(dispatch);
  }, [agencyInfoLoadingState, userAccount]);
};
