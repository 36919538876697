import React, { useEffect, useReducer, useState } from 'react';
import clsx from 'clsx';
import {
  AgxColumn,
  AgxSelect,
  AgxLabel,
  AgxCurrency,
  AgxRow,
  AgxBodyText,
  Images,
  AgxTextInput,
  AgxButton,
  AgxDivider,
  AgxErrorLabel,
  PropertyTypeOptions,
} from '@urbanx/agx-ui-components';
import { useFetchCMAPropertySearch } from 'store/cmaPropertySearch/cmaPropertySearchReducer';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { Breakpoints, ScreenSize } from 'utils/screen';
import { distanceOptions, maxDropDownNumberValue } from '../constants';
import { getFilterText } from './getFilterText';
import { filterReducer } from './filterReducer';
import FilterDropDown from './FilterDropDown';
import './SearchFilter.scss';
import useFormAddressSelector from 'selectors/useFormAddressSelector';
import useFormPropertyDetailsSelector from 'selectors/useFormPropertyDetailsSelector';

const SearchFilter = ({
  fromPrice,
  toPrice,
  propertyType,
  distanceOption,
  currentValues,
  onPreviousPageClick,
}) => {
  const initialFilters = {
    fromPrice: fromPrice != null && fromPrice !== '' ? Number(fromPrice) : null,
    toPrice: toPrice != null && toPrice !== '' ? Number(toPrice) : null,
    minBedrooms: null,
    maxBedrooms: null,
    minBathrooms: null,
    maxBathrooms: null,
    minCarSpaces: null,
    maxCarSpaces: null,
    minLandArea: null,
    maxLandArea: null,
    propertyType: propertyType,
    distanceOption: distanceOption || '',
  };

  const propertyDetails = useFormPropertyDetailsSelector();
  const [cmaInitiallyLoaded, setCmaInitiallyLoaded] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [filters, dispatchFilter] = useReducer(filterReducer, initialFilters);
  const [priceError, setPriceError] = useState('');
  const [landSizeError, setLandSizeError] = useState('');

  const propertyAddress = useFormAddressSelector();

  const [userAccount] = useAzureAuth();
  const fetchCMAPropertySearch = useFetchCMAPropertySearch();

  const [filterText, setFilterText] = useState(getFilterText(filters));

  const hasError =
    (filters.minBedrooms ?? 0) >
      (filters.maxBedrooms ?? maxDropDownNumberValue) ||
    (filters.minBathrooms ?? 0) >
      (filters.maxBathrooms ?? maxDropDownNumberValue) ||
    (filters.minCarSpaces ?? 0) >
      (filters.maxCarSpaces ?? maxDropDownNumberValue) ||
    priceError?.length > 0 ||
    landSizeError?.length > 0;

  const resetCmaResultScroll = () => {
    const desktopResults = document.getElementsByClassName('resultsPanel');
    const mobileResult = document.getElementsByClassName('mobileResults');

    if (desktopResults != null && desktopResults.length > 0) {
      desktopResults[0].scrollTop = 0;
    }

    if (mobileResult != null && mobileResult.length > 0) {
      mobileResult[0].scrollTop = 0;
    }
  };

  const loadCMA = async () => {
    if (!propertyDetails) return;

    setCmaInitiallyLoaded(true);

    try {
      const request = {
        propertyId: propertyDetails.propertyId,
        radius: filters.distanceOption,
        minBedrooms: filters.minBedrooms?.toString(),
        maxBedrooms: filters.maxBedrooms?.toString(),
        minBathrooms: filters.minBathrooms?.toString(),
        maxBathrooms: filters.maxBathrooms?.toString(),
        minCarSpaces: filters.minCarSpaces?.toString(),
        maxCarSpaces: filters.maxCarSpaces?.toString(),
        minPrice: filters.fromPrice,
        maxPrice: filters.toPrice,
        propertyType: filters.propertyType,
        minLandArea: filters.minLandArea,
        maxLandArea: filters.maxLandArea,
        address: propertyAddress?.formattedAddress,
        latitude: propertyDetails.latitude,
        longitude: propertyDetails.longitude,
      };

      const result = await fetchCMAPropertySearch(request);

      // ToDo: Handle unsuccessful calls to provide a good user experience
      if (result === 'success') {
        setIsCollapsed(false);
        resetCmaResultScroll();
      } else console.error('Failed to find any comparable properties');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!cmaInitiallyLoaded) {
      loadCMA();
    }
  }, [cmaInitiallyLoaded]);

  useEffect(() => {
    if (parseFloat(filters.fromPrice) >= parseFloat(filters.toPrice)) {
      setPriceError(`Value must be greater than ${filters.fromPrice}`);
    } else {
      setPriceError('');
    }

    if (filters.minLandArea > filters.maxLandArea) {
      setLandSizeError('A valid range is required');
    } else {
      setLandSizeError('');
    }
  }, [filters]);

  const handleCMAPropertySearch = async e => {
    e?.preventDefault();

    if (!userAccount) return;

    setFilterText(getFilterText(filters));

    await loadCMA();
  };

  const filtersClass = clsx('filters', { 'filters-opened': isCollapsed });
  const maxLengthForPrice = 11;

  const filterTextBox =
    ScreenSize() === Breakpoints.Desktop ? (
      <AgxRow mediumGap centered extraClasses={filtersClass}>
        <AgxTextInput
          id="filterText"
          defaultValue={filterText}
          rightIcon={
            <Images.OptionsOutline
              onClick={() => setIsCollapsed(!isCollapsed)}
            />
          }
          parentControlValue
          noOptionalLabel
          stretch
          readonly
          overflowEllipsis
        />
      </AgxRow>
    ) : (
      <AgxRow mediumGap centered extraClasses={filtersClass}>
        <div>
          <Images.Vector onClick={onPreviousPageClick} />
        </div>
        <div
          className="filterStyle"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <AgxLabel medium extraClasses={'filterTextStyle'}>
            {filterText}
          </AgxLabel>
          <Images.OptionsOutline />
        </div>
      </AgxRow>
    );

  return (
    <>
      {filterTextBox}
      <input
        id="collapsible"
        className="toggle"
        type="checkbox"
        checked={isCollapsed}
        readOnly
      />
      <label htmlFor="collapsible" className="lbl-toggle"></label>
      <AgxColumn
        fill
        extraClasses="wrap-collapsible"
        scrollable={ScreenSize() === Breakpoints.Mobile}
      >
        <AgxRow fill extraClasses="collapsible-content">
          <AgxColumn
            fill
            extraClasses="content-inner"
            scrollable={ScreenSize() === Breakpoints.Desktop}
          >
            <AgxColumn extraLargeGap>
              <AgxColumn largeGap>
                <AgxColumn smallGap fill>
                  <AgxRow fill centered>
                    <AgxRow fill>
                      <AgxLabel medium>Price Range</AgxLabel>
                    </AgxRow>
                    {currentValues.price && (
                      <AgxLabel extraClasses="neutral-grey-600" medium>
                        {currentValues.price}
                      </AgxLabel>
                    )}
                  </AgxRow>
                  <AgxRow mediumGap centered>
                    <AgxCurrency
                      id="fromPrice"
                      defaultValue={filters.fromPrice}
                      onInputValueChange={({ value }) =>
                        dispatchFilter({
                          prop: 'fromPrice',
                          value,
                        })
                      }
                      stretch
                      optionalLabelText=""
                      maxLength={maxLengthForPrice}
                    />
                    <AgxBodyText medium>-</AgxBodyText>
                    <AgxCurrency
                      id="toPrice"
                      defaultValue={filters.toPrice}
                      onInputValueChange={({ value }) =>
                        dispatchFilter({
                          prop: 'toPrice',
                          value,
                        })
                      }
                      stretch
                      optionalLabelText=""
                      maxLength={maxLengthForPrice}
                    />
                  </AgxRow>
                  {priceError && (
                    <AgxRow>
                      <AgxErrorLabel error={priceError} />
                    </AgxRow>
                  )}
                </AgxColumn>
                <AgxDivider />
                <FilterDropDown
                  label="Bedrooms"
                  getIcon={Images.Bed}
                  currentValue={currentValues.bedrooms}
                  minValue={filters.minBedrooms}
                  maxValue={filters.maxBedrooms}
                  updateMinValue={value =>
                    dispatchFilter({
                      prop: 'minBedrooms',
                      value,
                    })
                  }
                  updateMaxValue={value =>
                    dispatchFilter({
                      prop: 'maxBedrooms',
                      value,
                    })
                  }
                />
                <AgxDivider />
                <FilterDropDown
                  label="Bathrooms"
                  getIcon={Images.Bath}
                  currentValue={currentValues.bathrooms}
                  minValue={filters.minBathrooms}
                  maxValue={filters.maxBathrooms}
                  updateMinValue={value =>
                    dispatchFilter({
                      prop: 'minBathrooms',
                      value,
                    })
                  }
                  updateMaxValue={value =>
                    dispatchFilter({
                      prop: 'maxBathrooms',
                      value,
                    })
                  }
                />
                <AgxDivider />
                <FilterDropDown
                  label="Car Spaces"
                  getIcon={Images.Car}
                  currentValue={currentValues.carSpaces}
                  minValue={filters.minCarSpaces}
                  maxValue={filters.maxCarSpaces}
                  updateMinValue={value =>
                    dispatchFilter({
                      prop: 'minCarSpaces',
                      value,
                    })
                  }
                  updateMaxValue={value =>
                    dispatchFilter({
                      prop: 'maxCarSpaces',
                      value,
                    })
                  }
                />
                <AgxDivider />
                <AgxColumn>
                  <AgxRow fill centered>
                    <AgxRow fill>
                      <AgxLabel medium>Size</AgxLabel>
                    </AgxRow>
                    {currentValues.landArea && (
                      <AgxLabel extraClasses="neutral-grey-600" medium>
                        {currentValues.landArea}m²
                      </AgxLabel>
                    )}
                  </AgxRow>
                  <AgxRow mediumGap centered>
                    <AgxTextInput
                      id="fromLandArea"
                      required
                      defaultValue={filters.minLandArea}
                      onInputValueChange={({ value }) =>
                        dispatchFilter({
                          prop: 'minLandArea',
                          value:
                            value != null && value !== ''
                              ? Number(value)
                              : null,
                        })
                      }
                      stretch
                      landArea
                    />
                    <AgxBodyText medium>-</AgxBodyText>
                    <AgxTextInput
                      id="toLandArea"
                      required
                      defaultValue={filters.maxLandArea}
                      onInputValueChange={({ value }) =>
                        dispatchFilter({
                          prop: 'maxLandArea',
                          value:
                            value != null && value !== ''
                              ? Number(value)
                              : null,
                        })
                      }
                      stretch
                      landArea
                    />
                  </AgxRow>
                  {landSizeError && (
                    <AgxRow>
                      <AgxErrorLabel error={landSizeError} />
                    </AgxRow>
                  )}
                </AgxColumn>
                <AgxDivider />
                <AgxColumn>
                  <AgxRow fill centered>
                    <AgxRow fill>
                      <AgxLabel medium>Property Type</AgxLabel>
                    </AgxRow>
                    {currentValues.propertyType && (
                      <AgxLabel extraClasses="neutral-grey-600" medium>
                        {currentValues.propertyType}
                      </AgxLabel>
                    )}
                  </AgxRow>
                  <AgxRow mediumGap centered>
                    <AgxSelect
                      id="propertyType"
                      dataTestId="agx-propertyTypeDropdown"
                      options={PropertyTypeOptions}
                      onValueChanged={({ value }) =>
                        dispatchFilter({
                          prop: 'propertyType',
                          value,
                        })
                      }
                      defaultValue={{
                        label: filters.propertyType,
                        value: filters.propertyType,
                      }}
                      hideOptionalLabel
                      isMini
                    />
                  </AgxRow>
                </AgxColumn>
                <AgxDivider />
                <AgxRow mediumGap>
                  <AgxSelect
                    id="distanceOptions"
                    options={distanceOptions}
                    onValueChanged={({ value }) =>
                      dispatchFilter({
                        prop: 'distanceOption',
                        value,
                      })
                    }
                    defaultValue={{
                      label: filters.distanceOption,
                      value: filters.distanceOption,
                    }}
                    hideOptionalLabel
                    isMini
                    label="Search Radius"
                  />
                </AgxRow>
              </AgxColumn>
              {(isCollapsed ||
                ScreenSize() === Breakpoints.Desktop ||
                ScreenSize() === Breakpoints.Tablet_Landscape ||
                ScreenSize() === Breakpoints.Tablet_Portrait) && (
                <AgxRow extraClasses="filterActionButtons" mediumGap>
                  <AgxButton
                    text="Cancel"
                    hollow
                    large
                    wide
                    onClick={() => setIsCollapsed(false)}
                  />

                  <AgxButton
                    text="Search"
                    primary
                    large
                    wide
                    disabled={hasError}
                    onClick={e => handleCMAPropertySearch(e)}
                  />
                </AgxRow>
              )}
            </AgxColumn>
          </AgxColumn>
        </AgxRow>
      </AgxColumn>
    </>
  );
};

export default SearchFilter;
