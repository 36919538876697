import { useMemo } from 'react';
import { ActionButtonType, AgxButton } from '@urbanx/agx-ui-components';
import { useAppSelector } from 'hooks/useAppSelector';
import { ConnectionState } from 'utils/connectionState';

interface FormButtonsProps {
  actionButtonType: ActionButtonType;
  currentPageNum: number;
  totalPageNum: number;
  goToPreviousPage: () => void;
  goToNextPage: () => void;
  reviewForm: () => void;
}

const FormButtons = ({
  actionButtonType,
  currentPageNum,
  totalPageNum,
  goToPreviousPage,
  goToNextPage,
  reviewForm,
}: FormButtonsProps) => {
  const { connectionState } = useAppSelector(state => state.notifications);
  const { proceedToNextPage, proceedToPrevPage } = useAppSelector(
    state => state.config
  );

  const actionButtonTypeValue = useMemo(() => {
    const isLastPage = currentPageNum >= totalPageNum - 1;

    if (
      !isLastPage &&
      [ActionButtonType.BackAndReview, ActionButtonType.BackAndSubmit].includes(
        actionButtonType
      )
    ) {
      return currentPageNum === 0
        ? ActionButtonType.Forward
        : ActionButtonType.ForwardAndBack;
    }

    return actionButtonType;
  }, [actionButtonType, currentPageNum, totalPageNum]);

  const renderButton = {
    [ActionButtonType.ForwardAndBack]: (
      <div className="agx-form-buttons">
        <AgxButton
          key={`back-${currentPageNum}`}
          dataTestId={`agx-backBtn`}
          text="Back"
          onClick={goToPreviousPage}
          hollow
          large
          disabled={!proceedToPrevPage}
        />
        <AgxButton
          key={`next-${currentPageNum}`}
          dataTestId={`agx-nextBtn`}
          text="Next"
          onClick={goToNextPage}
          disabled={!proceedToNextPage}
          primary
          large
        />
      </div>
    ),
    [ActionButtonType.BackAndReview]: (
      <div className="agx-form-buttons">
        <AgxButton
          key={`back-${currentPageNum}`}
          dataTestId={`agx-backBtn`}
          text="Back"
          hollow
          large
          onClick={goToPreviousPage}
          disabled={!proceedToPrevPage}
        />
        <AgxButton
          key={`review-${currentPageNum}`}
          text="Review"
          dataTestId={`agx-reviewBtn`}
          onClick={reviewForm}
          primary
          large
          disabled={connectionState !== ConnectionState.Connected}
        />
      </div>
    ),
    [ActionButtonType.BackAndSubmit]: (
      <div className="agx-form-buttons">
        <AgxButton
          key={`back-${currentPageNum}`}
          text="Back"
          dataTestId={`agx-backBtn`}
          hollow
          large
          onClick={goToPreviousPage}
          disabled={!proceedToPrevPage}
        />
        <AgxButton
          key={`submit-${currentPageNum}`}
          dataTestId={`agx-submitBtn`}
          text="Submit"
          onClick={reviewForm}
          primary
          large
          disabled={connectionState !== ConnectionState.Connected}
        />
      </div>
    ),
    [ActionButtonType.Forward]: (
      <div className="agx-form-buttons">
        <AgxButton
          key={`next-${currentPageNum}`}
          dataTestId={`agx-nextBtn`}
          text="Next"
          onClick={goToNextPage}
          disabled={!proceedToNextPage}
          primary
          large
        />
      </div>
    ),
  };

  return renderButton[actionButtonTypeValue as keyof typeof renderButton];
};

export default FormButtons;
