import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  AgxButton,
  AgxBodyText,
  Images,
  AgxColumn,
  AgxRow,
  AgxRadio,
  FormType,
  PoolDocumentation,
  PoolDocumentationType,
  useWindowSize,
  Breakpoints,
} from '@urbanx/agx-ui-components';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { LoadingState } from 'utils/loadingState';
import { openFileInNewTab } from 'utils/openFileInNewTab';
import { setAndShowErrorToast } from 'store/config';
import { campaignsApi } from 'Api/Campaigns/campaignsApi';
import { ReactComponent as AlertIcon } from './assets/icons/alert-triangleOutline.svg';
import { ReactComponent as CheckmarkIcon } from './assets/icons/checkmark-circleOutline.svg';
import './PoolSafetyCertificate.scss';

interface PoolSafetyCertificateProps {
  id: string;
  onValueChanged: (value: {
    id: string;
    value?: PoolDocumentation | null;
  }) => void;
  defaultValue?: PoolDocumentation | null;
  required: boolean;
  validate: boolean;
}

const expiryWarningThreshold = 60;

const PoolSafetyCertificate = ({
  id,
  onValueChanged,
  defaultValue,
  required,
  validate,
}: PoolSafetyCertificateProps) => {
  const [, getAuthToken] = useAzureAuth();
  const windowSize = useWindowSize();
  const { selectedForm } = useAppSelector(state => state.form);
  const {
    loadingState: poolCertLoadingState,
    poolSafetyCertificate,
    buildingCertificate,
  } = useAppSelector(state => state.poolDocumentation);

  const poolSafetyCertificateExpiry =
    poolSafetyCertificate?.expiryDate ??
    defaultValue?.poolSafetyCertificate?.expiryDate;

  const buildingCertificateExpiry =
    buildingCertificate?.expiryDate ??
    defaultValue?.buildingCertificate?.expiryDate;

  const [poolDocumentationType, setPoolDocumentationType] = useState(
    defaultValue?.poolDocumentationType
  );
  const [hasPool, setHasPool] = useState(
    !defaultValue ? undefined : !!poolDocumentationType
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    const poolDocumentation = {
      poolDocumentationType: poolDocumentationType,
      poolSafetyCertificate:
        poolSafetyCertificate ?? defaultValue?.poolSafetyCertificate,
      buildingCertificate:
        poolDocumentationType === PoolDocumentationType.BuildingCertificate
          ? {
              expiryDate: (
                buildingCertificate ?? defaultValue?.buildingCertificate
              )?.expiryDate,
              document: defaultValue?.buildingCertificate?.document,
            }
          : undefined,
      noPoolCertificateInfo: defaultValue?.noticeOfNoPoolCertificate,
    } as PoolDocumentation;

    onValueChanged({ id, value: poolDocumentation });
  }, [poolDocumentationType, poolSafetyCertificate, buildingCertificate]);

  const certificateExpiresInDays = !!poolSafetyCertificateExpiry
    ? dayjs(poolSafetyCertificateExpiry).diff(dayjs(), 'days')
    : !!buildingCertificateExpiry
      ? dayjs(buildingCertificateExpiry).diff(dayjs(), 'days')
      : null;

  const onHasPoolOptionChange = (hasPool: boolean) => {
    setHasPool(hasPool);
    if (hasPool) {
      const hasPoolCertificate = !!(
        poolSafetyCertificate ?? defaultValue?.poolSafetyCertificate
      );
      const hasBuildingCertificate = !!(
        buildingCertificate ?? defaultValue?.buildingCertificate
      );

      if (hasBuildingCertificate && !hasPoolCertificate) {
        setPoolDocumentationType(PoolDocumentationType.BuildingCertificate);
      } else if (hasPoolCertificate) {
        setPoolDocumentationType(PoolDocumentationType.PoolSafetyCertificate);
      } else {
        setPoolDocumentationType(PoolDocumentationType.NoPoolCertificate);
      }
    } else {
      setPoolDocumentationType(undefined);
    }
  };

  const downloadDocument = async () => {
    if (!getAuthToken || !defaultValue?.poolSafetyCertificate) return;

    const authToken = await getAuthToken();

    if (!authToken) return;

    const { containerFilePath } =
      defaultValue?.poolSafetyCertificate?.document ?? {};

    if (!containerFilePath) return;

    try {
      const { data: fileLink } = await campaignsApi(authToken).get<string>(
        'GetUploadedFileLink',
        {
          filePath: containerFilePath,
        }
      );

      if (fileLink) {
        openFileInNewTab(windowSize === Breakpoints.Desktop, fileLink);
      }
    } catch (err: any) {
      console.error(err);
      dispatch(setAndShowErrorToast(err.message));
      return err.message;
    }
  };

  const getPoolCertComponent = () => {
    if (!poolDocumentationType) return undefined;

    if (poolCertLoadingState === LoadingState.Loading) {
      return [<React.Fragment key="loading">Loading...</React.Fragment>];
    } else if (
      poolDocumentationType === PoolDocumentationType.PoolSafetyCertificate ||
      poolDocumentationType === PoolDocumentationType.BuildingCertificate
    ) {
      const icon =
        certificateExpiresInDays &&
        certificateExpiresInDays < expiryWarningThreshold ? (
          <AlertIcon className="agx-pool-icon" />
        ) : (
          <CheckmarkIcon className="agx-pool-icon" />
        );

      const label = certificateExpiresInDays && (
        <AgxBodyText medium>
          {poolDocumentationType === PoolDocumentationType.PoolSafetyCertificate
            ? 'Pool'
            : 'Building'}{' '}
          certificate will expire in {certificateExpiresInDays} days
        </AgxBodyText>
      );

      return [
        <AgxRow fill largeGap key="viewPoolCert">
          {icon}
          <AgxColumn fill largeGap extraClasses="viewPoolCert">
            {label}
            {poolDocumentationType ===
              PoolDocumentationType.PoolSafetyCertificate && (
              <AgxButton
                id="viewPoolCertificate"
                text="View pool certificate"
                primary
                small
                onClick={(): void => {
                  downloadDocument();
                }}
              />
            )}
          </AgxColumn>
        </AgxRow>,
      ];
    } else if (
      poolDocumentationType === PoolDocumentationType.NoPoolCertificate
    ) {
      return [
        <AgxColumn fill extraLargeGap key="noCert">
          <AgxRow largeGap centered>
            <AlertIcon className="agx-pool-alertIcon" />
            <AgxBodyText medium>No Pool Certificate found</AgxBodyText>
          </AgxRow>
          {selectedForm?.formType !== FormType.ListingSubmission && (
            <AgxBodyText medium>
              Form 36 Notice of No Pool Certificate will generate
            </AgxBodyText>
          )}
        </AgxColumn>,
      ];
    }
  };

  return (
    <div className="agx-radio">
      <label className="agx-radio-label">Pool Safety</label>
      <div className="agx-radio-group">
        <AgxRadio
          id="hasPool"
          label="Has Pool or Spa"
          onCheckChanged={() => onHasPoolOptionChange(true)}
          checked={hasPool === true}
          subContent={getPoolCertComponent()}
        />
        <AgxRadio
          id="noPool"
          label="No Pool"
          onCheckChanged={() => onHasPoolOptionChange(false)}
          checked={hasPool === false}
        />
      </div>
      {validate && required && !defaultValue && (
        <div className="errorMessage">
          <Images.AlertCircle />
          <AgxBodyText small extraClasses="error">
            {'Pool Safety selection required'}
          </AgxBodyText>
        </div>
      )}
    </div>
  );
};

export default PoolSafetyCertificate;
