import {
  AgxLabel,
  AgxColumn,
  AgxYesNoQuestion,
  YesNo,
} from '@urbanx/agx-ui-components';
import { useState, useEffect } from 'react';
import WaiverOfCoolingOff from './WaiverOfCoolingOff';

const AdditionalDocumentations = ({
  id,
  defaultValue,
  onValueChanged,
  validate,
}) => {
  const [additionalDocumentations, setadditionalDocumentations] = useState({
    isMultipleOfferForm: defaultValue?.isMultipleOfferForm ?? false,
    waiverOfCoolingOff: defaultValue?.waiverOfCoolingOff,
  });

  useEffect(() => {
    onValueChanged({
      id,
      value: {
        ...additionalDocumentations,
      },
    });
  }, [additionalDocumentations]);

  return (
    <AgxColumn veryLargeGap>
      <AgxLabel large>Additional forms</AgxLabel>
      <AgxColumn mediumGap fill>
        <AgxYesNoQuestion
          id="multipleofferform"
          key="multipleofferform"
          onValueChanged={value =>
            setadditionalDocumentations({
              ...additionalDocumentations,
              isMultipleOfferForm: value.value === YesNo.Yes ? true : false,
            })
          }
          defaultValue={
            additionalDocumentations.isMultipleOfferForm ? YesNo.Yes : YesNo.No
          }
          label="Multiple offer form"
          reverseLabelOrder
          noLabel="Not required"
          yesLabel="Yes, include Multiple offer form"
        />
      </AgxColumn>
      <WaiverOfCoolingOff
        key="WaiverOfCoolingOff"
        id="WaiverOfCoolingOff"
        onValueChanged={value =>
          setadditionalDocumentations({
            ...additionalDocumentations,
            waiverOfCoolingOff: value,
          })
        }
        defaultValue={additionalDocumentations.waiverOfCoolingOff}
        validate={validate}
      />
    </AgxColumn>
  );
};

export default AdditionalDocumentations;
