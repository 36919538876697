import { useMemo } from 'react';
import { useAppSelector } from './useAppSelector';

const defaultDomainBackendPrice = 5000000;

export const usePropertyPriceSelector = () => {
  const { campaigns } = useAppSelector(state => state.campaigns);
  const {
    campaignId,
    formValues: { BackendPrice, EstimatedSellingPrice },
  } = useAppSelector(state => state.form);

  const relevantCampaign = useMemo(
    () => campaigns.find(c => c.campaignId === campaignId),
    [campaigns, campaignId]
  );

  const relevantPriceRange = useMemo(
    () => EstimatedSellingPrice ?? BackendPrice,
    [EstimatedSellingPrice, BackendPrice]
  );

  const propertyPrice = useMemo(() => {
    let price: number | null = defaultDomainBackendPrice;

    if (relevantPriceRange != null) {
      const backendPriceToBeConfirmed =
        relevantPriceRange?.toBeConfirmed ?? false;

      if (!backendPriceToBeConfirmed) {
        if (relevantPriceRange?.priceRange != null) {
          const { upperRange, lowerRange } = relevantPriceRange.priceRange;
          price = upperRange ?? lowerRange;
        } else if (relevantCampaign?.backendPriceRange) {
          const { upperRange, lowerRange } = relevantCampaign.backendPriceRange;
          price = upperRange ?? lowerRange;
        }
      }
    }

    if (price == null || Number.isNaN(Number(price))) {
      price = defaultDomainBackendPrice;
    }

    return price;
  }, [relevantPriceRange, relevantCampaign]);

  return propertyPrice;
};
