import React, { useState, useEffect } from 'react';
import {
  AgxControlledCheckbox,
  AgxCurrency,
  AgxTextInput,
  AgxRow,
  AgxButton,
  Images,
} from '@urbanx/agx-ui-components';
import { Breakpoints, ScreenSize } from 'utils/screen';
import '../MarketingPackages.scss';

const CustomMarketingItem = ({
  id,
  productName,
  pricePerUnit,
  selected,
  onSelectProductChange,
  deleteRow,
  validate,
}) => {
  const [customMarketingItem, setCustomMarketingItem] = useState(productName);
  const [price, setPrice] = useState(pricePerUnit);
  const [productSelected, setProductSelected] = useState(selected);

  const isTabletLandscapeSizeOrDesktopSize =
    ScreenSize() === Breakpoints.Tablet_Landscape ||
    ScreenSize() === Breakpoints.Desktop;

  useEffect(() => {
    setProductSelected(selected);
  }, [selected]);

  useEffect(() => {
    if (pricePerUnit === 0) {
      setPrice(null);
    } else {
      setPrice(pricePerUnit);
    }
  }, [pricePerUnit]);

  useEffect(() => {
    const number = Number(price);

    onSelectProductChange({
      id,
      productName: customMarketingItem,
      pricePerUnit: !Number.isNaN(price)
        ? number <= 0 || !number
          ? 0
          : number
        : 0,
      value: productSelected,
    });
  }, [productSelected, customMarketingItem, price]);

  const onValueChanged = value => {
    setProductSelected(value === true);
  };

  const onInputValueChange = ({ value }) => {
    const newValue = value?.toString()?.replace(/,/g, '');
    setPrice(newValue ? Number(newValue) : null);
  };

  const validateCustomMarketingItemName =
    validate && productSelected && customMarketingItem === '';

  const validateCustomMarketingItemPrice =
    validate &&
    productSelected &&
    (price === null || price === undefined || price === '');

  const validatingCustomMarketingItem =
    validateCustomMarketingItemName || validateCustomMarketingItemPrice;

  return (
    <AgxRow
      fill
      spaceBetween
      centered
      mediumGap
      extraClasses={`marketingPackageItem custom ${validatingCustomMarketingItem && 'error'}`}
    >
      <AgxControlledCheckbox
        naked
        checked={productSelected ?? true}
        onValueChanged={onValueChanged}
      />
      <AgxTextInput
        id="customMarketingItem"
        onInputValueChange={({ value }) => {
          setCustomMarketingItem(value);
        }}
        noHeader
        stretch
        defaultValue={customMarketingItem}
        noOptionalLabel
        error={validateCustomMarketingItemName}
        validate={validateCustomMarketingItemName}
        extraClasses="customItemLabel"
        hideErrorDescription
      />
      <AgxCurrency
        id={`marketingpackage-item-text-${productName}-${id}`}
        defaultValue={price}
        onInputValueChange={onInputValueChange}
        decimalPoints={2}
        noHeader
        small
        alignRight
        formatDecimalsOnBlur
        placeholder="0.00"
        displayErrors={validateCustomMarketingItemPrice}
        error={validateCustomMarketingItemPrice && 'Enter price'}
        validate={validateCustomMarketingItemPrice}
        extraClasses="customItemPrice"
      />
      <AgxButton
        minimal
        rightIcon={<Images.CrossFill />}
        extraClasses={
          isTabletLandscapeSizeOrDesktopSize && 'marketingItemDeleteBtn'
        }
        onClick={() =>
          deleteRow({
            id: id,
            productName: customMarketingItem,
            pricePerUnit: !Number.isNaN(price) ? Number(price) : null,
            value: productSelected,
          })
        }
      />
    </AgxRow>
  );
};

export default CustomMarketingItem;
