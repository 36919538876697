import {
  AgxColumn,
  AgxLabel,
  AgxRow,
  AgxButton,
  AgxBodyText,
} from '@urbanx/agx-ui-components';
import { formatCurrencyAsNumber } from 'utils/formatNumber';
import { MarketingItemType } from 'Api/Marketing/Types/Marketing';
import PriceItem from './MarketingItems/PriceItem';
import ComplimentaryItem from './MarketingItems/ComplimentaryItem';
import AgentToFillItem from './MarketingItems/AgentToFillItem';
import DomainItem from './MarketingItems/DomainItem';
import RealEstateItem from './MarketingItems/RealEstateItem';
import CustomMarketingItem from './MarketingItems/CustomMarketingItem';
import './MarketingPackageList.scss';

const MarketingPackageList = ({
  selectedPackage,
  showSelectRealEstate,
  showSelectDomain,
  availablePackageInclusions,
  availablePackageAddons,
  realEstateProductName,
  domainProductName,
  onSelectProductsChange,
  addCustomMarketingItemRow,
  deleteCustomMarketingItemRow,
  validate,
}) => {
  const specialItemSortIndex = itemName => {
    // REA and Domain aren't in the original package list,
    // so we give them a negative sort order to put them at the top
    if (itemName === realEstateProductName) {
      return -2;
    } else if (itemName === domainProductName) {
      return -1;
    } else {
      return 0;
    }
  };

  const sortPackageItems = baseList => (a, b) => {
    // Function to sort the package items on the campaign by the order they
    // are stored in the list from the API.
    let aIndex = baseList.findIndex(p => p.id === a.id);
    let bIndex = baseList.findIndex(p => p.id === b.id);

    if (aIndex === -1) {
      aIndex = specialItemSortIndex(a.id);
    }

    if (bIndex === -1) {
      bIndex = specialItemSortIndex(b.id);
    }

    return aIndex > bIndex ? 1 : -1;
  };

  const onSelectInclusionChange = ({
    id,
    productName,
    pricePerUnit,
    value,
  }) => {
    const updatedItems = [
      ...(selectedPackage?.packageInclusions?.filter(i => i.id !== id) || []),
      ...(value ? [{ id, productName, pricePerUnit }] : []),
    ].sort(sortPackageItems(availablePackageInclusions));

    onSelectProductsChange({
      ...selectedPackage,
      packageInclusions: updatedItems,
    });
  };

  const onSelectAddonChange = ({ id, productName, pricePerUnit, value }) => {
    const updatedAddons = [
      ...(selectedPackage?.packageAddons?.filter(i => i.id !== id) || []),
      ...(value ? [{ id, productName, pricePerUnit }] : []),
    ].sort(sortPackageItems(availablePackageAddons));

    onSelectProductsChange({
      ...selectedPackage,
      packageAddons: updatedAddons,
    });
  };

  const inclusionSubtotal = () => {
    return (
      selectedPackage.packageInclusions?.reduce((pv, cv) => {
        const pricePerUnit = Number(cv?.pricePerUnit);
        return pv + (!Number.isNaN(pricePerUnit) ? pricePerUnit : 0);
      }, 0) ?? 0
    );
  };

  const addonSubtotal = () => {
    return (
      selectedPackage.packageAddons?.reduce((pv, cv) => {
        const pricePerUnit = Number(cv?.pricePerUnit);
        return pv + (!Number.isNaN(pricePerUnit) ? pricePerUnit : 0);
      }, 0) ?? 0
    );
  };

  const getMarketingItemComponent = (
    id,
    productName,
    pricePerUnit,
    itemType,
    isSelected,
    onSelectProductChange
  ) => {
    if (itemType === MarketingItemType.Price) {
      return (
        <PriceItem
          key={`priceItemMarketingpackage-item-${selectedPackage.packageName}-${id}`}
          id={id}
          productName={productName ?? ''}
          pricePerUnit={pricePerUnit ?? ''}
          selected={isSelected}
          onSelectProductChange={onSelectProductChange}
        />
      );
    } else if (itemType === MarketingItemType.Complementary) {
      return (
        <ComplimentaryItem
          key={`complimentaryMarketingpackage-item-${selectedPackage.packageName}-${id}`}
          id={id}
          productName={productName}
          selected={isSelected}
          onSelectProductChange={onSelectProductChange}
        />
      );
    } else if (itemType === MarketingItemType.AgentToFill) {
      return (
        <AgentToFillItem
          key={`agentFillMarketingpackage-item-${selectedPackage.packageName}-${id}`}
          id={id}
          productName={productName}
          pricePerUnit={pricePerUnit}
          selected={isSelected}
          validate={validate}
          onSelectProductChange={onSelectProductChange}
        />
      );
    } else if (itemType === MarketingItemType.Custom) {
      return (
        <CustomMarketingItem
          key={`customMarketingpackage-item-${selectedPackage.packageName}-${id}`}
          id={id}
          productName={productName}
          pricePerUnit={pricePerUnit}
          selected={isSelected}
          onSelectProductChange={onSelectProductChange}
          deleteRow={deleteCustomMarketingItemRow}
          validate={validate}
        />
      );
    }
  };

  const CUSTOM_PACKAGE_ITEMS_LIMIT = 3;

  const customPackageItemsCountOnInclusions = availablePackageInclusions.filter(
    obj => obj.itemType === MarketingItemType.Custom
  ).length;

  const customPackageItemsCountOnAddons = availablePackageAddons.filter(
    obj => obj.itemType === MarketingItemType.Custom
  ).length;

  const hasNoAddons = availablePackageAddons?.length === 0 ?? true;
  const displayTotal =
    availablePackageInclusions && availablePackageInclusions.length > 0;

  return (
    <AgxColumn extraClasses="marketingPackageItems">
      {availablePackageInclusions && availablePackageInclusions.length > 0 && (
        <AgxColumn fill extraClasses="marketingPackageItemList">
          <AgxLabel large>{selectedPackage.packageName} Inclusions</AgxLabel>
          {showSelectRealEstate && (
            <RealEstateItem
              key={`marketingpackage-item-${selectedPackage.packageName}-${realEstateProductName}`}
              id={realEstateProductName}
              productName={realEstateProductName}
              selected={selectedPackage?.packageInclusions?.some(
                i => i.productName === realEstateProductName
              )}
              onSelectProductChange={onSelectInclusionChange}
            />
          )}
          {showSelectDomain && (
            <DomainItem
              key={`marketingpackage-item-${selectedPackage.packageName}-${domainProductName}`}
              id={domainProductName}
              productName={domainProductName}
              selected={selectedPackage?.packageInclusions?.some(
                i => i.productName === domainProductName
              )}
              onSelectProductChange={onSelectInclusionChange}
            />
          )}
          {availablePackageInclusions?.map(
            ({ id, productName, pricePerUnit, itemType }) => {
              const selectedInclusion =
                selectedPackage?.packageInclusions?.find(i => i.id === id);
              return getMarketingItemComponent(
                id,
                productName,
                selectedInclusion?.pricePerUnit ?? pricePerUnit,
                itemType,
                selectedInclusion != null,
                onSelectInclusionChange
              );
            }
          )}
          {customPackageItemsCountOnInclusions < CUSTOM_PACKAGE_ITEMS_LIMIT && (
            <AgxRow centered extraClasses="marketingPackageItem">
              <AgxButton
                text="Add Item"
                primary
                small
                shrinkOnLargerDevices
                onClick={() => addCustomMarketingItemRow(true)}
                extraClasses="addItemBtn"
              />
            </AgxRow>
          )}
          {!hasNoAddons && (
            <AgxRow fill spaceBetween extraClasses="marketingPackageSubtotal">
              <span className="subTotalTitle">Subtotal</span>
              <AgxBodyText small extraClasses="subTotalValue">
                ${formatCurrencyAsNumber(inclusionSubtotal())}
              </AgxBodyText>
            </AgxRow>
          )}
        </AgxColumn>
      )}
      {availablePackageAddons && availablePackageAddons.length > 0 && (
        <AgxColumn fill extraClasses="marketingPackageItemList">
          <AgxLabel large>Upgrades</AgxLabel>
          {availablePackageAddons?.map(
            ({ id, productName, pricePerUnit, itemType, value }) => {
              const selectedAddon = selectedPackage?.packageAddons?.find(
                i => i.id === id
              );
              return getMarketingItemComponent(
                id,
                productName,
                selectedAddon?.pricePerUnit ?? pricePerUnit,
                itemType,
                selectedAddon != null,
                onSelectAddonChange
              );
            }
          )}
          {customPackageItemsCountOnAddons < CUSTOM_PACKAGE_ITEMS_LIMIT && (
            <AgxRow centered extraClasses="marketingPackageItem">
              <AgxButton
                text="Add Item"
                primary
                small
                shrinkOnLargerDevices
                onClick={() => addCustomMarketingItemRow(false)}
                extraClasses="addItemBtn"
              />
            </AgxRow>
          )}
          <AgxRow fill spaceBetween extraClasses="marketingPackageSubtotal">
            <span className="subTotalTitle">Subtotal</span>
            <AgxBodyText small extraClasses="subTotalValue">
              ${formatCurrencyAsNumber(addonSubtotal())}
            </AgxBodyText>
          </AgxRow>
        </AgxColumn>
      )}
      {displayTotal && (
        <AgxRow fill spaceBetween centered extraClasses="marketingPackageTotal">
          <AgxBodyText medium extraClasses="totalTitle">
            Total
          </AgxBodyText>
          <AgxBodyText small extraClasses="totalValue">
            ${formatCurrencyAsNumber(inclusionSubtotal() + addonSubtotal())}
          </AgxBodyText>
        </AgxRow>
      )}
    </AgxColumn>
  );
};

export default MarketingPackageList;
