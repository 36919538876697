import { useEffect, useMemo, useState } from 'react';
import { PoolDocumentation } from '@urbanx/agx-ui-components';
import { LoadingState } from 'utils/loadingState';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useFormCampaignId from 'hooks/useFormCampaignId';
import useFormPropertyDetailsSelector from 'selectors/useFormPropertyDetailsSelector';
import {
  fetchPoolCertificate,
  clearPoolCertificateData,
} from 'features/poolCertificate/poolCertificateReducer';

const PoolSafetyCertificatePreloader = () => {
  const [, getAuthToken] = useAzureAuth();
  const dispatch = useAppDispatch();

  const [isFetching, setIsFetching] = useState(false);
  const propertyDetails = useFormPropertyDetailsSelector();
  const campaignId = useFormCampaignId();
  const {
    loadingState: poolCertLoadingState,
    lotNumber: poolCertLotNumber,
    planType: poolCertPlanType,
    planNumber: poolCertPlanNumber,
  } = useAppSelector(state => state.poolDocumentation);

  const formPoolDocumentation = useAppSelector(
    state => state.form.formValues.PoolDocumentation
  ) as PoolDocumentation;

  const primaryParcel = useMemo(
    () =>
      propertyDetails?.parcels?.find(p => p.isPrimaryPlan) ??
      propertyDetails?.parcels?.[0],
    [propertyDetails]
  );

  useEffect(() => {
    if (
      poolCertLoadingState === LoadingState.Loaded ||
      poolCertLoadingState === LoadingState.Failed
    ) {
      setIsFetching(false);
    }
  }, [poolCertLoadingState]);

  useEffect(() => {
    if (
      primaryParcel &&
      (primaryParcel.lotNumber !== poolCertLotNumber ||
        primaryParcel.planType !== poolCertPlanType ||
        primaryParcel.planNumber !== poolCertPlanNumber)
    ) {
      dispatch(clearPoolCertificateData());
    }
  }, [primaryParcel]);

  useEffect(() => {
    if (!getAuthToken) return;
    if (isFetching || poolCertLoadingState !== LoadingState.NotLoaded) return;
    if (
      !campaignId ||
      !primaryParcel?.lotNumber ||
      !primaryParcel?.planType ||
      !primaryParcel.planNumber
    )
      return;

    if (!!formPoolDocumentation?.poolSafetyCertificate?.document) return;

    setIsFetching(true);

    getAuthToken().then(async token => {
      if (!token) return;

      dispatch(
        fetchPoolCertificate(
          token,
          campaignId,
          primaryParcel.lotNumber,
          primaryParcel.planType,
          primaryParcel.planNumber
        )
      );
    });
  }, [
    poolCertLoadingState,
    campaignId,
    formPoolDocumentation,
    primaryParcel?.lotNumber,
    primaryParcel?.planType,
    primaryParcel?.planNumber,
  ]);

  return null;
};

export default PoolSafetyCertificatePreloader;
