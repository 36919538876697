import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  AgxButton,
  AgxRow,
  AgxColumn,
  AgxDivider,
  AgxSelect,
  AgxTextArea,
  AgxTextInput,
  AgxCaption,
  AgxColours,
  AgxLabel,
  AgxHeader,
  RenderAnimation,
  RenderAnimationType,
  AustralianState,
} from '@urbanx/agx-ui-components';
import { sendForm } from 'Api/Campaigns/campaignsApi';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { setAndShowErrorToast } from 'store/config';
import { markCampaignsAsStale } from '../../campaigns/campaignsReducer';
import { useFormSettings } from 'hooks/useFormSettings';
import { LoadingState } from 'utils/loadingState';
import { Breakpoints, ScreenSize } from 'utils/screen';
import { useFormClearer } from 'hooks/useFormCleaner';
import { cleanStreetAddress } from 'utils/formatAddress';
import { CreateEmailMessage } from './EmailMessage';
import { prepareSignatories } from 'utils/vendorBuyerUtil';
import useFormId from 'hooks/useFormId';
import './SendViaDocuSignForm.scss';

const { NEUTRAL_GREY_700 } = AgxColours;

export const SendViaDocuSignForm = ({
  onGoBack,
  agentSignsFirst,
  emailStyle,
}) => {
  const selectedForm = useSelector(state => state.form.selectedForm);

  const navigate = useNavigate();
  const clearFormData = useFormClearer();
  const [, getAuthToken] = useAzureAuth();
  const updateFormSettings = useFormSettings();
  const dispatch = useDispatch();
  const formId = useFormId();

  const agentId = useSelector(state => state.agentInfo.agentId);
  const {
    campaignId,
    formValues: {
      PropertyAddress: address,
      Vendors: campaignVendors,
      ListingAgents: { leadAgentId, secondaryAgentId },
    },
  } = useSelector(state => state.form);

  const {
    agents: { loadingState: agentsLoaded, items: agents },
    agencyInfo: {
      info: { branding },
    },
  } = useSelector(state => state.agencies);

  const agencyName = branding?.agencyName;

  const [signatories, setSignatories] = useState([]);
  const [agentIdSendTo, setAgentIdSendTo] = useState(leadAgentId ?? agentId);
  const [emailSubject, setEmailSubject] = useState(
    `${selectedForm.state === AustralianState.QLD ? 'Form 6' : 'Agency Agreement'} - ${cleanStreetAddress(address)}`
  );
  const [emailMessage, setEmailMessage] = useState(null);
  const [showDocuSignLoader, setShowDocuSignLoader] = useState(false);
  const [showSuccessLoader, setShowSuccessLoader] = useState(false);

  useEffect(() => {
    updateFormSettings({
      formBackgroundInverted: showDocuSignLoader || showSuccessLoader,
      growContent: showDocuSignLoader || showSuccessLoader,
    });
  }, [showDocuSignLoader, showSuccessLoader]);

  const agentOptions = useMemo(() => {
    if (agentsLoaded !== LoadingState.Loaded) return [];

    return (
      agents
        ?.filter(
          a =>
            a.licenceType > 0 ||
            (leadAgentId && a.id === leadAgentId) ||
            (secondaryAgentId && a.id === secondaryAgentId)
        )
        .map(agent => {
          return {
            label: `${agent.name.firstName} ${agent.name.lastName} (${agent.id})`,
            value: agent.id,
          };
        }) ?? []
    );
  }, [agentsLoaded, agents, leadAgentId, secondaryAgentId]);

  useEffect(() => {
    setSignatories(prepareSignatories(campaignVendors, 'Vendor'));
  }, [campaignVendors]);

  useEffect(() => {
    if (
      agentsLoaded !== LoadingState.Loaded ||
      signatories.length === 0 ||
      emailMessage != null
    )
      return;

    const sendToAgent = agents.find(a => a.id === (agentIdSendTo ?? agentId));

    if (sendToAgent == null) return;

    setEmailMessage(
      CreateEmailMessage(
        emailStyle,
        address,
        signatories,
        sendToAgent,
        agencyName
      )
    );
  }, [agentsLoaded, agents, signatories, agentIdSendTo, agentId]);

  const checkAndSendViaDocuSign = () => {
    const selectedAgent = agents.find(a => a.id === agentIdSendTo);
    if (selectedAgent) {
      sendToDocusign(selectedAgent);
    } else {
      dispatch(setAndShowErrorToast('Invalid Signing Agent'));
    }
  };

  const sendToDocusign = async selectedAgent => {
    setShowDocuSignLoader(true);
    updateFormSettings({
      showBreadcrumbs: false,
      displayTitleVisible: false,
    });
    getAuthToken().then(async token => {
      try {
        await sendForm(token, {
          campaignId,
          formId,
          formType: selectedForm.formType,
          state: selectedForm.state,
          digitalSignatureSubmission: {
            vendorDetails: {
              emailOverrides: Object.fromEntries(
                signatories.map(s => [s.id, s.email])
              ),
              subject: emailSubject,
              body: emailMessage,
            },
            agentId: selectedAgent.id,
            agentName: `${selectedAgent.name.firstName} ${selectedAgent.name.lastName}`,
            sendVendorsNow: true,
          },
        });
        setTimeout(() => {
          setShowDocuSignLoader(false);
          setShowSuccessLoader(true);
          setTimeout(() => {
            dispatch(markCampaignsAsStale());
            clearFormData();
            navigate('/');
          }, 1800);
        }, 1500);
      } catch (err) {
        console.error(err);
        dispatch(setAndShowErrorToast(err.message));
        return err.message;
      }
    });
  };

  const signatoryInputs = signatories.map(
    ({ id, email, name, type }, index) => (
      <AgxTextInput
        id={`signatory-${id}`}
        key={`signatory-${id}`}
        label={name}
        defaultValue={email}
        optionalLabelText={`${type} ${index + 1}`}
        stretch
        onInputValueChange={({ value }) => {
          setSignatories(
            signatories.map(s => {
              if (s.id === id) s.email = value;
              return s;
            })
          );
        }}
      />
    )
  );

  const agentSelect = agentOptions && agentOptions.length > 0 && (
    <AgxSelect
      id={'agents'}
      key={'agents'}
      label={'Signing Agent'}
      defaultValue={
        agentOptions.find(ao => ao.value === agentIdSendTo) ?? agentOptions[0]
      }
      optionalLabelText={'Agent'}
      options={agentOptions}
      hideOptionalLabel={true}
      onValueChanged={({ value }) => {
        setAgentIdSendTo(value);
      }}
    />
  );

  const agentColumn = (
    <AgxColumn fill largeGap>
      {agentSelect}
    </AgxColumn>
  );
  const vendorColumn = (
    <AgxColumn fill largeGap>
      {signatoryInputs}
    </AgxColumn>
  );

  if (showDocuSignLoader)
    return (
      <AgxColumn
        fill
        centerJustified
        centered
        extraClasses="sendForSigningStatusContent"
      >
        <RenderAnimation icon={RenderAnimationType.Paper} />
        <AgxColumn fill centered largeGap>
          <AgxHeader size={1} inverse>
            One moment
          </AgxHeader>
          <AgxHeader size={3} secondary>
            Sending for Signing...
          </AgxHeader>
        </AgxColumn>
      </AgxColumn>
    );

  if (showSuccessLoader)
    return (
      <AgxColumn
        fill
        centerJustified
        centered
        extraClasses="sendForSigningStatusContent"
      >
        <RenderAnimation icon={RenderAnimationType.Champagne} />
        <AgxHeader size={1} inverse>
          Success!
        </AgxHeader>
      </AgxColumn>
    );

  const isReadyToSubmit =
    (signatories?.length ?? 0) > 0 && agentIdSendTo != null;

  return (
    <AgxColumn largeGap>
      <AgxLabel large>Signatories</AgxLabel>
      {!agentSignsFirst ? (
        <>
          {vendorColumn}
          {agentColumn}
          <AgxCaption colour={NEUTRAL_GREY_700}>
            Vendor(s) receives DocuSign first, Signing Agent will receive once
            all Vendor(s) have signed.
          </AgxCaption>
        </>
      ) : (
        <>
          {agentColumn}
          {vendorColumn}
          <AgxCaption colour={NEUTRAL_GREY_700}>
            Agent receives the DocuSign first, vendor(s) will receive DocuSign
            when agent has signed
          </AgxCaption>
        </>
      )}
      <AgxDivider margin expanded />
      <AgxColumn fill largeGap>
        <AgxLabel large data-testid="agx-docuSignHeadingEmail">
          Email
        </AgxLabel>
        <AgxTextInput
          id="agx-docuSignEmailSubject"
          label="Subject"
          required
          defaultValue={emailSubject}
          onInputValueChange={({ value }) => setEmailSubject(value)}
          stretch
        />
        {emailMessage != null && (
          <AgxTextArea
            id="agx-docuSignEmailMessage"
            label="Message"
            required
            defaultValue={emailMessage}
            onInputValueChange={({ value }) => setEmailMessage(value)}
            rows={ScreenSize() === Breakpoints.Mobile ? 9 : 16}
            stretch
          />
        )}
      </AgxColumn>
      <AgxDivider margin expanded />
      <AgxColumn fill largeGap>
        {ScreenSize() === Breakpoints.Desktop && (
          <AgxRow largeGap fill justifyCenter>
            <AgxButton
              hollow
              large
              text="Back"
              dataTestId="agx-docuSignBack"
              onClick={onGoBack}
            />
            <AgxButton
              primary
              large
              text="Send for DocuSigning"
              dataTestId="agx-docuSignSendForDocuSigning"
              onClick={checkAndSendViaDocuSign}
              disabled={!isReadyToSubmit}
            />
          </AgxRow>
        )}
        {ScreenSize() === Breakpoints.Mobile && (
          <AgxColumn largeGap fill centered>
            <AgxButton
              primary
              large
              wide
              text="Send for DocuSigning"
              dataTestId="agx-docuSignSendForDocuSigning"
              onClick={checkAndSendViaDocuSign}
              disabled={!isReadyToSubmit}
            />
            <AgxButton
              hollow
              large
              wide
              text="Back"
              dataTestId="agx-docuSignBack"
              onClick={onGoBack}
            />
          </AgxColumn>
        )}
        {(ScreenSize() === Breakpoints.Tablet_Landscape ||
          ScreenSize() === Breakpoints.Tablet_Portrait) && (
          <AgxColumn largeGap fill centered>
            <AgxButton
              primary
              large
              wide
              text="Send for DocuSigning"
              dataTestId="agx-docuSignSendForDocuSigning"
              onClick={checkAndSendViaDocuSign}
              disabled={!isReadyToSubmit}
            />
            <AgxButton
              hollow
              large
              wide
              text="Back"
              dataTestId="agx-docuSignBack"
              onClick={onGoBack}
            />
          </AgxColumn>
        )}
      </AgxColumn>
    </AgxColumn>
  );
};
