import moment, { Moment } from 'moment';

const getTime = (
  number: number,
  withoutSuffix: boolean,
  key: string,
  isFuture: boolean
) => {
  let val = '';
  switch (key) {
    case 'ss': {
      val = relativeTimeWithAmPm(moment().subtract(number, 'seconds'));
      break;
    }
    case 'm': {
      val = relativeTimeWithAmPm(moment().subtract(number, 'minute'));
      break;
    }
    case 'mm': {
      val = relativeTimeWithAmPm(moment().subtract(number, 'minutes'));
      break;
    }
    case 'h': {
      val = relativeTimeWithAmPm(moment().subtract(number, 'hour'));
      break;
    }
    case 'hh': {
      val = relativeTimeWithAmPm(moment().subtract(number, 'hours'));
      break;
    }
  }
  return val;
};

// Customized the From now
moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'Just now',
    ss: getTime,
    m: getTime,
    mm: getTime,
    h: getTime,
    hh: getTime,
    d: '1 day ago',
    dd: (
      number: number,
      withoutSuffix: boolean,
      key: string,
      isFuture: boolean
    ) => {
      // Here, we lose access to the reference date directly
      // You can implement alternative logic here if needed
      return number === 1 ? '1 day ago' : `${number} days ago`;
    },
  },
});

const relativeTimeWithAmPm = (date: Moment) => {
  const relativeTime = moment().fromNow();
  const formattedTime = moment(date).format('h:mm A');
  return relativeTime
    .replace('Just now', '')
    .replace(/(ago|in)/, `${formattedTime}`);
};

export const getMomentFromNow = (utcDate: string): string => {
  const futureDate = moment(utcDate);
  const hoursDiff = futureDate.diff(moment(), 'hours');

  return Math.abs(hoursDiff) > 24
    ? moment.utc(utcDate).local().format('DD/MM/YY')
    : moment.utc(utcDate).local().fromNow(true);
};
