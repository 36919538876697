import { useEffect, useMemo } from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { LoadingState } from 'utils/loadingState';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { usePropertyPriceSelector } from 'hooks/usePropertyPriceSelector';
import { ExternalIntegrations } from 'Api/Agencies/Types/Agencies';
import {
  fetchMarketingItems,
  fetchMarketingPackages,
} from '../../marketing/marketingReducer';
import { fetchDomain, reloadDomain } from '../../domain/domainReducer';
import {
  fetchRealEstate,
  reloadRealEstate,
} from '../../realEstate/realEstateReducer';
import useFormAddressSelector from 'selectors/useFormAddressSelector';
import useFormPropertyDetailsSelector from 'selectors/useFormPropertyDetailsSelector';
import { ListingType } from 'Api/RealEstate/Types/types';

function getRealEstateListingType(propertyType?: string | null): ListingType {
  switch (propertyType) {
    case 'House':
    case 'Unit/Apartment':
    case 'Townhouse':
    case 'Villa':
      return ListingType.Residential;
    case 'Residential Land':
      return ListingType.Land;
    case 'Rural':
      return ListingType.Rural;
    default:
      return ListingType.Residential;
  }
}

const MarketingPackagesPreloader = () => {
  const [, getAuthToken] = useAzureAuth();
  const dispatch = useAppDispatch();
  const propertyDetails = useFormPropertyDetailsSelector();
  const { loadingState: realEstateLoadingState, key: realEstateKey } =
    useAppSelector(state => state.realEstate);
  const {
    loadingState: domainLoadingState,
    key: domainKey,
    estimatedValue,
  } = useAppSelector(state => state.domain);
  const {
    agencyInfo: { info },
  } = useAppSelector(state => state.agencies);
  const {
    marketingPackages: { loadingState: marketingPackagesLoadingState },
    marketingItems: { loadingState: marketingItemsLoadingState },
  } = useAppSelector(state => state.marketing);
  const { campaignId } = useAppSelector(state => state.form);
  const externalIntegrations = info?.externalIntegrations;

  const realEstateIntegrationEnabled = useMemo(() => {
    if (externalIntegrations) {
      return (
        externalIntegrations?.some(
          i => i === ExternalIntegrations.RealEstateDotCom
        ) ?? false
      );
    }

    return false;
  }, [externalIntegrations]);

  const domainIntegrationEnabled = useMemo(() => {
    if (externalIntegrations) {
      return (
        externalIntegrations?.some(
          i => i === ExternalIntegrations.DomainDotCom
        ) ?? false
      );
    }

    return false;
  }, [externalIntegrations]);

  const propertyAddress = useFormAddressSelector();

  const backendPrice = usePropertyPriceSelector();

  const listingType = useMemo(() => {
    return getRealEstateListingType(propertyDetails?.propertyType);
  }, [propertyDetails?.propertyType]);

  useEffect(() => {
    if (propertyAddress == null) return;

    const generatedRealEstateKey = `${propertyAddress.suburb}|${propertyAddress.state}|${propertyAddress.postalCode}|${listingType}`;

    if (realEstateKey !== generatedRealEstateKey) {
      dispatch(reloadRealEstate());
    }
  }, [propertyAddress, listingType]);

  useEffect(() => {
    if (!propertyAddress || !domainKey) return;

    const generatedDomainKey = `${propertyAddress.suburb}|${propertyAddress.state}|${propertyAddress.postalCode}`;

    if (domainKey !== generatedDomainKey) {
      dispatch(reloadDomain());
    }
  }, [propertyAddress]);

  useEffect(() => {
    if (backendPrice !== estimatedValue) {
      dispatch(reloadDomain());
    }
  }, [backendPrice, estimatedValue]);

  useEffect(() => {
    if (
      !realEstateIntegrationEnabled ||
      realEstateLoadingState !== LoadingState.NotLoaded ||
      !propertyAddress?.suburb ||
      !propertyAddress?.state ||
      !propertyAddress?.postalCode
    )
      return;

    getAuthToken().then(token => {
      if (!token || !campaignId) return;

      dispatch(
        fetchRealEstate(token, {
          campaignId,
          suburb: propertyAddress.suburb,
          state: propertyAddress.state,
          postcode: propertyAddress.postalCode,
          country: 'Australia',
          listingType,
        })
      );
    });
  }, [realEstateLoadingState, propertyAddress, campaignId, listingType]);

  useEffect(() => {
    if (
      !domainIntegrationEnabled ||
      domainLoadingState !== LoadingState.NotLoaded ||
      !propertyAddress?.suburb ||
      !propertyAddress?.state ||
      !propertyAddress?.postalCode ||
      backendPrice === estimatedValue
    )
      return;

    getAuthToken().then(token => {
      if (!token || !campaignId) return;
      dispatch(
        fetchDomain(
          token,
          campaignId,
          propertyAddress.suburb,
          propertyAddress.state,
          propertyAddress.postalCode,
          backendPrice
        )
      );
    });
  }, [domainLoadingState, backendPrice, propertyAddress, campaignId]);

  useEffect(() => {
    if (marketingItemsLoadingState !== LoadingState.NotLoaded) return;

    getAuthToken().then(token => {
      if (!token) return;
      dispatch(fetchMarketingItems(token));
    });
  }, [marketingItemsLoadingState]);

  useEffect(() => {
    if (marketingPackagesLoadingState !== LoadingState.NotLoaded) return;

    getAuthToken().then(token => {
      if (!token) return;
      dispatch(fetchMarketingPackages(token));
    });
  }, [marketingPackagesLoadingState]);

  return null;
};

export default MarketingPackagesPreloader;
