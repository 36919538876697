import React, { useEffect, useState } from 'react';
import {
  AgxControlledCheckbox,
  AgxCurrency,
  AgxRow,
} from '@urbanx/agx-ui-components';
import '../MarketingPackages.scss';

const AgentToFillItem = ({
  id,
  productName,
  pricePerUnit,
  selected,
  validate,
  onSelectProductChange,
}) => {
  const [agentPrice, setAgentPrice] = useState(pricePerUnit);
  const [productSelected, setProductSelected] = useState(selected);

  useEffect(() => {
    setProductSelected(selected);
  }, [selected]);

  useEffect(() => {
    if (pricePerUnit === 0) {
      setAgentPrice(null);
    } else {
      setAgentPrice(pricePerUnit);
    }
  }, [pricePerUnit]);

  useEffect(() => {
    const number = Number(agentPrice);

    onSelectProductChange({
      id,
      productName,
      pricePerUnit: !Number.isNaN(agentPrice)
        ? number <= 0 || !number
          ? 0
          : number
        : 0,
      value: productSelected,
    });
  }, [agentPrice, productSelected]);

  const onValueChanged = value => {
    setProductSelected(value === true);
  };

  const onInputValueChange = ({ value }) => {
    const newValue = value?.toString()?.replace(/,/g, '');
    setAgentPrice(newValue ? Number(newValue) : null);
  };

  const validateAgentPrice =
    validate &&
    productSelected &&
    (agentPrice === null || agentPrice === undefined || agentPrice === '');

  return (
    <AgxRow
      fill
      spaceBetween
      centered
      mediumGap
      extraClasses="marketingPackageItem"
    >
      <AgxControlledCheckbox
        naked
        label={productName}
        checked={productSelected}
        onValueChanged={onValueChanged}
      />
      <AgxCurrency
        id={`marketingpackage-item-text-${productName}-${id}`}
        required
        defaultValue={agentPrice}
        onInputValueChange={onInputValueChange}
        decimalPoints={2}
        formatDecimalsOnBlur
        small
        placeholder="0.00"
        alignRight
        extraClasses={`agentToFillCurrency ${validateAgentPrice && 'error'}`}
        displayErrors={validateAgentPrice}
        error={validateAgentPrice && 'Enter price'}
        validate={validateAgentPrice}
      />
    </AgxRow>
  );
};

export default AgentToFillItem;
