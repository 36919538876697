import { useState } from 'react';
import {
  AgxBodyText,
  AgxButton,
  AgxColumn,
  AgxHeader,
  AgxSparkleForm,
  Breakpoints,
  formatCurrency,
  Images,
  SPECIAL_CHARACTERS,
  StakeholderType,
  useWindowSize,
} from '@urbanx/agx-ui-components';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { selectContract } from '../../../../../../campaigns/campaignsReducer';
import { cleanTwoLineAddress } from 'utils/formatAddress';
import FormDetailsHeader from '../../Common/FormDetailsHeader';
import { setAndShowErrorToast } from 'store/config';
import { useFormLoader } from 'hooks/useFormLoader';
import { formatListOfStrings } from 'utils/formatString';
import { getAllBuyerNames, prepareSignatories } from 'utils/vendorBuyerUtil';

const ResumeContractSubmission = ({
  campaign,
  contract,
  lastUpdate,
  mobile = false,
}) => {
  const { address, campaignId } = campaign;

  const lastUpdatedOn = `Last updated ${moment(lastUpdate).format('DD/MM/YY')}`;
  const screenSize = useWindowSize();
  const isMobile = screenSize === Breakpoints.Mobile;
  const [addressLineOne, addressLineTwo] = cleanTwoLineAddress(address);

  const dispatch = useDispatch();
  const { loadForm } = useFormLoader();
  const [disableButtons, setDisableButtons] = useState(false);

  const resumeContractSubmission = async () => {
    try {
      setDisableButtons(true);

      await loadForm(campaignId, contract.contractId);
    } catch (err) {
      console.error(err);
      dispatch(setAndShowErrorToast('Failed to resume Contract submission'));
    }

    setDisableButtons(false);
  };
  const allBuyers = prepareSignatories(contract.buyers, 'Buyer');
  const allBuyerNames = getAllBuyerNames(allBuyers);

  return (
    <>
      <FormDetailsHeader
        formName="Contract"
        detail={lastUpdatedOn}
        onBack={() => dispatch(selectContract(null))}
      />
      <AgxColumn centered veryLargeGap extraClasses="resumeFormDetails">
        <AgxHeader size={2} centered>
          {addressLineOne},
          <br />
          {addressLineTwo}
        </AgxHeader>
        <AgxBodyText medium centered>
          {formatListOfStrings(allBuyerNames)} {SPECIAL_CHARACTERS.BULLET}{' '}
          {formatCurrency(contract.purchasePrice, 0)}
        </AgxBodyText>
        <AgxColumn smallGap centered>
          <AgxBodyText medium centered>
            There are more fields to fill,
          </AgxBodyText>
          <AgxBodyText medium centered>
            let's continue from where you left off
          </AgxBodyText>
        </AgxColumn>
        <AgxSparkleForm
          width={isMobile ? 150 : 200}
          height={isMobile ? 150 : 200}
        />
      </AgxColumn>
      <div className="campaignModalButtons">
        <AgxButton
          hollow={!mobile}
          primary={mobile}
          wide={mobile}
          large
          text="Resume Submission"
          onClick={resumeContractSubmission}
          shrinkOnLargerDevices
          disabled={disableButtons}
        />
      </div>
    </>
  );
};

export default ResumeContractSubmission;
