import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  AgxButton,
  AgxRow,
  AgxColumn,
  AgxBodyText,
  AgxLabel,
  Images,
} from '@urbanx/agx-ui-components';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { CMAPropertyImageCard } from './CMAPropertyImageCard';
import { CMAPropertySelectedCard } from './CMAPropertySelectedCard';
import { Breakpoints, ScreenSize } from 'utils/screen';
import { formatCurrency } from 'utils/formatNumber';
import { SearchMap } from './SearchMap';
import SearchFilter from './SearchFilter/SearchFilter';
import './CMAPropertyResultPage.scss';
import { useFetchMoreCMAProperties } from 'store/cmaPropertySearch/cmaPropertySearchReducer';
import { LoadingState } from 'utils/loadingState';
import { PropertyResultsLoader } from './PropertyResultsLoader';
import useFormPropertyDetailsSelector from 'selectors/useFormPropertyDetailsSelector';

export const CMAPropertyResultPage = ({
  onPreviousPageClick,
  onShowSelectedProperties,
  maxSelectedProperties,
}) => {
  const [userAccount, getAuthToken] = useAzureAuth();
  const dispatch = useDispatch();
  const propertyDetails = useFormPropertyDetailsSelector();
  const [selectedCmaProperties, setSelectedCmaProperties] = useState([]);
  const [previousSelectedCmaProperties, setPreviousSelectedCmaProperties] =
    useState([]);

  const { BackendPrice } = useSelector(state => state.form.formValues);

  const [selectedComparablesExpanded, setSelectedComparablesExpanded] =
    useState(false);

  const fetchMoreCMAProperties = useFetchMoreCMAProperties();

  const lowerRange = BackendPrice?.priceRange?.lowerRange;
  const upperRange = BackendPrice?.priceRange?.upperRange;
  const backendPriceToBeConfirmed = BackendPrice?.toBeConfirmed ?? false;

  let lowerRangeFilter = null;
  let upperRangeFilter = null;

  if (upperRange && !backendPriceToBeConfirmed) {
    upperRangeFilter = upperRange * 1.1;
  }

  if (lowerRange && !backendPriceToBeConfirmed) {
    lowerRangeFilter = lowerRange * 0.9;
    if (lowerRangeFilter < 0) lowerRangeFilter = 0;

    if (!upperRangeFilter) {
      upperRangeFilter = (lowerRangeFilter / 0.9) * 1.1;
    }
  }

  const fromPrice = lowerRangeFilter?.toFixed(0)?.toString() ?? null;
  const toPrice = upperRangeFilter?.toFixed(0)?.toString() ?? null;
  const propertyType = propertyDetails?.propertyType ?? null;
  const distanceOption = 2;

  const {
    cmaPropertyResults,
    page,
    totalPages,
    totalProperties,
    cmaPropertyResultsLoadingState,
    cmaPropertyResultsLoadingMore,
  } = useSelector(({ cmaPropertySearch }) => cmaPropertySearch);

  const selectedPropertyResults = useMemo(
    () =>
      cmaPropertyResults?.filter(cmaProperty =>
        selectedCmaProperties?.some(
          selectedCmaProperty =>
            selectedCmaProperty.propertyId === cmaProperty.propertyId
        )
      ) ?? [],
    [cmaPropertyResults, selectedCmaProperties]
  );

  const propertyLocations = useMemo(() => {
    return cmaPropertyResults?.map(({ latitude, longitude, propertyId }) => {
      return {
        latitude,
        longitude,
        selected: selectedCmaProperties?.some(
          selectedProperty => selectedProperty.propertyId === propertyId
        ),
      };
    });
  }, [cmaPropertyResults, selectedCmaProperties]);

  useEffect(() => {
    const excludedSelectedCmaProperties = selectedCmaProperties.filter(
      selectedProperty => {
        return !cmaPropertyResults?.some(
          cmaProperty => cmaProperty.propertyId === selectedProperty.propertyId
        );
      }
    );
    setPreviousSelectedCmaProperties(excludedSelectedCmaProperties);
  }, [cmaPropertyResults]);

  const allSelectedAddresses = selectedCmaProperties.map(selectedProperty => {
    const {
      address,
      bathrooms,
      bedrooms,
      carSpaces,
      distanceFromProperty,
      landArea,
      price,
      settlementDate,
    } = selectedProperty;

    return {
      PropertyAddress: address,
      SoldPrice: price,
      DistanceInKilometers: distanceFromProperty,
      PropertyDetails: {
        NumberOfBeds: bedrooms,
        NumberOfBathrooms: bathrooms,
        NumberOfCarSpaces: carSpaces,
        TotalLandArea: landArea,
      },
      settlementDate,
    };
  });

  const filterCurrentValues = useMemo(() => {
    return {
      price:
        lowerRange && !backendPriceToBeConfirmed
          ? formatCurrency(lowerRange, 0)
          : null,
      bedrooms: propertyDetails?.bed ?? null,
      bathrooms: propertyDetails?.bath ?? null,
      carSpaces: propertyDetails?.car ?? null,
      landArea: propertyDetails?.landArea ?? null,
      propertyType: propertyDetails?.propertyType ?? null,
    };
  }, [propertyDetails, lowerRange, backendPriceToBeConfirmed]);

  const handlePropertyImageCheck = useCallback(
    property => {
      const propertyExists = selectedCmaProperties?.some(
        selectedProperty => selectedProperty.propertyId === property.propertyId
      );

      if (propertyExists) {
        setSelectedCmaProperties(
          selectedCmaProperties?.filter(
            selectedProperty =>
              selectedProperty.propertyId !== property.propertyId
          ) ?? []
        );
        setPreviousSelectedCmaProperties(
          previousSelectedCmaProperties?.filter(
            selectedProperty =>
              selectedProperty.propertyId !== property.propertyId
          ) ?? []
        );
      } else {
        if (selectedCmaProperties?.length === maxSelectedProperties) return;
        setSelectedCmaProperties([...selectedCmaProperties, property]);
      }
    },
    [
      selectedCmaProperties,
      setSelectedCmaProperties,
      setPreviousSelectedCmaProperties,
      previousSelectedCmaProperties,
      maxSelectedProperties,
    ]
  );

  const handleLoadMoreProperties = useCallback(async () => {
    if (!userAccount || cmaPropertyResultsLoadingMore) return;

    try {
      await fetchMoreCMAProperties();
    } catch (error) {
      console.error(error);
    }
  }, [
    userAccount,
    getAuthToken,
    dispatch,
    fetchMoreCMAProperties,
    cmaPropertyResultsLoadingMore,
  ]);

  const getTimeAgo = useCallback(property => {
    const { settlementDate } = property;
    if (!settlementDate) return '';

    const days = moment().diff(settlementDate, 'days');
    const timeAgo =
      days <= 25 ? `${days} days` : moment(settlementDate).fromNow(true);

    return timeAgo;
  }, []);

  const results = (
    <AgxColumn largeGap fill>
      {cmaPropertyResultsLoadingState === LoadingState.Loading && (
        <PropertyResultsLoader />
      )}
      {cmaPropertyResultsLoadingState === LoadingState.Loaded &&
        cmaPropertyResults?.length > 0 &&
        cmaPropertyResults.map(cmaPropertyResult => {
          const timeAgo = getTimeAgo(cmaPropertyResult);

          return (
            <CMAPropertyImageCard
              key={cmaPropertyResult.propertyId}
              property={cmaPropertyResult}
              onPropertyImageCheck={handlePropertyImageCheck}
              selectedCmaProperty={selectedCmaProperties}
              timeAgo={timeAgo}
            />
          );
        })}
      {cmaPropertyResultsLoadingState === LoadingState.Loaded &&
        (!cmaPropertyResults || cmaPropertyResults.length === 0) && (
          <AgxBodyText large>
            No results could be found, try expanding your filters
          </AgxBodyText>
        )}
    </AgxColumn>
  );

  const previousResults = (
    <AgxColumn largeGap fill>
      {previousSelectedCmaProperties?.length > 0 &&
        [...previousSelectedCmaProperties].map(property => {
          const timeAgo = getTimeAgo(property);

          return (
            <CMAPropertyImageCard
              key={property.propertyId}
              property={property}
              onPropertyImageCheck={handlePropertyImageCheck}
              selectedCmaProperty={previousSelectedCmaProperties}
              timeAgo={timeAgo}
            />
          );
        })}
    </AgxColumn>
  );

  if (
    ScreenSize() === Breakpoints.Desktop ||
    ScreenSize() === Breakpoints.Tablet_Landscape ||
    ScreenSize() === Breakpoints.Tablet_Portrait
  ) {
    return (
      <>
        <div className="cmaResultsCloseButton" onClick={onPreviousPageClick}>
          <Images.CloseOutline />
        </div>
        <div className="desktopCmaResultsGrid">
          <AgxColumn fill extraClasses="cmaResults">
            <SearchFilter
              fromPrice={fromPrice}
              toPrice={toPrice}
              currentValues={filterCurrentValues}
              propertyType={propertyType}
              distanceOption={distanceOption}
              onPreviousPageClick={onPreviousPageClick}
            />
            <AgxColumn largeGap extraClasses="resultsPanel">
              {previousSelectedCmaProperties.length > 0 && (
                <AgxColumn largeGap>
                  <AgxLabel medium>Your previous selections:</AgxLabel>
                  {previousResults}
                </AgxColumn>
              )}
              <AgxRow spaceBetween>
                <AgxLabel medium>{totalProperties ?? 0} results</AgxLabel>
                <AgxBodyText small>Recommended</AgxBodyText>
              </AgxRow>
              {results}
              {cmaPropertyResults?.length > 0 && page < totalPages - 1 && (
                <AgxButton
                  text={
                    !cmaPropertyResultsLoadingMore ? 'Load More' : 'Loading...'
                  }
                  medium
                  hollow
                  shrinkOnLargerDevices
                  onClick={handleLoadMoreProperties}
                  disabled={cmaPropertyResultsLoadingMore}
                />
              )}
            </AgxColumn>
          </AgxColumn>
          <div className="cmaMap">
            <SearchMap locationsToMark={propertyLocations} />
          </div>
          <AgxColumn extraClasses="mapActions">
            <AgxRow justifyCenter mediumGap>
              <AgxButton
                id="clearAllProperties"
                hollow
                large
                text="Clear all"
                onClick={e => {
                  e.preventDefault();
                  setSelectedCmaProperties([]);
                }}
                disabled={selectedCmaProperties?.length === 0}
              />
              <AgxButton
                id="confirmProperties"
                primary
                large
                text="Confirm"
                disabled={selectedCmaProperties?.length === 0}
                onClick={() => {
                  onShowSelectedProperties(allSelectedAddresses);
                }}
              />
            </AgxRow>
          </AgxColumn>
        </div>
      </>
    );
  }

  return (
    <AgxColumn fill>
      <SearchFilter
        fromPrice={fromPrice}
        toPrice={toPrice}
        currentValues={filterCurrentValues}
        propertyType={propertyType}
        distanceOption={distanceOption}
        onPreviousPageClick={onPreviousPageClick}
      />
      <div className="mobileMap">
        <SearchMap locationsToMark={cmaPropertyResults} />
      </div>
      <AgxColumn mediumGap scrollable extraClasses="mobileResults">
        <div className="mobileResultsInnerContent">
          {previousSelectedCmaProperties.length > 0 && (
            <AgxColumn largeGap extraClasses="cmaResultsHeader">
              <AgxLabel medium>Your previous selections:</AgxLabel>
              {previousResults}
            </AgxColumn>
          )}
          <AgxRow spaceBetween extraClasses="cmaResultsHeader">
            <AgxLabel medium>{totalProperties ?? 0} results</AgxLabel>
            <AgxBodyText small>Recommended</AgxBodyText>
          </AgxRow>
          {results}
          {cmaPropertyResults?.length > 0 && page < totalPages - 1 && (
            <AgxColumn extraClasses="cmaMobileLoadMore">
              <AgxButton
                text={
                  !cmaPropertyResultsLoadingMore ? 'Load More' : 'Loading...'
                }
                medium
                hollow
                onClick={handleLoadMoreProperties}
                disabled={cmaPropertyResultsLoadingMore}
              />
            </AgxColumn>
          )}
        </div>
      </AgxColumn>
      <AgxColumn veryLargeGap extraClasses="mobileActionButtons">
        <AgxRow
          mediumGap
          centered
          spaceBetween
          onClick={() =>
            setSelectedComparablesExpanded(!selectedComparablesExpanded)
          }
        >
          <AgxLabel medium extraClasses="label">
            {selectedCmaProperties?.length ?? 0} Comparables Selected
          </AgxLabel>
          {selectedComparablesExpanded && <Images.ChevronDown />}
          {!selectedComparablesExpanded && <Images.ChevronUp />}
        </AgxRow>
        {selectedComparablesExpanded && selectedPropertyResults?.length > 0 && (
          <AgxRow mediumGap scrollable>
            {selectedPropertyResults?.map(cmaPropertyResult => (
              <CMAPropertySelectedCard
                key={cmaPropertyResult.propertyId}
                property={cmaPropertyResult}
                onCloseSelectedCard={handlePropertyImageCheck}
              />
            ))}
          </AgxRow>
        )}
        <AgxRow justifyCenter mediumGap>
          <AgxButton
            id="clearAllProperties"
            hollow
            large
            text="Clear all"
            onClick={e => {
              e.preventDefault();
              setSelectedCmaProperties([]);
              setPreviousSelectedCmaProperties(
                previousSelectedCmaProperties.map(property => ({
                  ...property,
                  checked: false,
                }))
              );
            }}
            disabled={selectedCmaProperties?.length === 0}
            wide
          />
          <AgxButton
            id="confirmProperties"
            primary
            large
            text="Confirm"
            disabled={selectedCmaProperties?.length === 0}
            onClick={() => {
              onShowSelectedProperties(allSelectedAddresses);
            }}
            wide
          />
        </AgxRow>
      </AgxColumn>
    </AgxColumn>
  );
};

export default CMAPropertyResultPage;
