import React from 'react';
import {
  AgxBodyText,
  AgxButton,
  AgxColumn,
  AgxHeader,
  AgxRow,
  useWindowSize,
  Breakpoints,
  PropertyAddress,
} from '@urbanx/agx-ui-components';
import { cleanTwoLineAddress } from 'utils/formatAddress';
import { ReactComponent as QuestionMark } from '../../assets/icons/question-mark-circle-outline.svg';
import './CannotAccessRural.scss';

interface CannotAccessRuralProps {
  address: PropertyAddress | null;
  onSearchAgain: () => void;
  onCancel: () => void;
}

const CannotAccessRural: React.FC<CannotAccessRuralProps> = ({
  address,
  onSearchAgain,
  onCancel,
}) => {
  const windowSize = useWindowSize();
  const [addressLineOne, addressLineTwo] = cleanTwoLineAddress(address);

  const searchAgainButton = (
    <AgxButton
      primary
      large
      text="Search again"
      onClick={onSearchAgain}
      wide={windowSize === Breakpoints.Mobile}
    />
  );

  const cancelButton = (
    <AgxButton
      hollow
      large
      text="Cancel"
      dark
      onClick={onCancel}
      wide={windowSize === Breakpoints.Mobile}
    />
  );

  return (
    <AgxColumn
      centerJustified
      centered
      extraLargeGap
      extraClasses="cannotAccessRural"
    >
      <QuestionMark width={48} height={48} />
      <AgxColumn
        centerJustified
        centered
        extraLargeGap={windowSize !== Breakpoints.Mobile}
        largeGap={windowSize === Breakpoints.Mobile}
      >
        <AgxBodyText medium centered secondary>
          {addressLineOne}
          <br />
          {addressLineTwo}
        </AgxBodyText>

        <AgxHeader inverse centered size={2}>
          This property is over 20 hectares
        </AgxHeader>
      </AgxColumn>
      <AgxBodyText centered inverse medium>
        You do not have access to generate Rural Agency Agreements. If you
        require access, please contact UrbanX.
      </AgxBodyText>
      {windowSize === Breakpoints.Mobile ? (
        <AgxColumn largeGap fill>
          {searchAgainButton}
          {cancelButton}
        </AgxColumn>
      ) : (
        <AgxRow largeGap>
          {searchAgainButton}
          {cancelButton}
        </AgxRow>
      )}
    </AgxColumn>
  );
};

export default CannotAccessRural;
