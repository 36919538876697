import React from 'react';
import {
  AgxBodyText,
  AgxButton,
  AgxColumn,
  AgxHeader,
  AgxLabel,
  AgxRow,
  Breakpoints,
  Company,
  Images,
  LandAreaType,
  useWindowSize,
} from '@urbanx/agx-ui-components';
import { cleanTwoLineAddress } from 'utils/formatAddress';
import placeHolderImage from 'assets/images/placeholder-property.png';
import { AllPropertyDetails } from 'Api/RpData/Types/types';
import { Tabs } from '../../PropertySearch';
import './PropertyImageCard.scss';

interface PropertyImageCardProps {
  currentTab: Tabs;
  allPropertyDetails: AllPropertyDetails;
  onClick: (details: AllPropertyDetails) => void;
}

export const PropertyImageCard: React.FC<PropertyImageCardProps> = ({
  currentTab,
  allPropertyDetails,
  onClick,
}) => {
  const windowSize = useWindowSize();
  const isDesktopSize = windowSize === Breakpoints.Desktop;

  if (!allPropertyDetails) return null;

  const {
    propertyDetails,
    propertyImageUrls,
    hasPool,
    matchedAddress: address,
    vendors,
  } = allPropertyDetails;
  const primaryParcel = propertyDetails.parcels?.find(p => p.isPrimaryPlan);

  const detailSections: Record<string, any[]> = {};

  const ownerNames = vendors
    ?.map(vendor => {
      if (vendor.company != null) {
        const { company } = vendor ?? {};
        const companyEntity = company as Company & { name: string };

        return companyEntity.name;
      } else if (vendor.individual != null) {
        const { individual } = vendor ?? {};
        const { firstName, lastName, middleName } = individual?.name ?? {};

        return [firstName, middleName, lastName]
          .filter(namePart => (namePart && namePart?.length > 0) ?? false)
          .join(' ');
      }

      return null;
    })
    .filter(vendor => vendor != null);

  const propertyNumbers = [];

  const ownerNameElements = {
    dataTestId: 'agx-ownerNames',
    name: 'Owner(s)',
    detail: isDesktopSize ? (
      <>
        {ownerNames?.map((name, idx) => (
          <React.Fragment key={`ownerName-${idx}`}>
            {name}
            {idx !== ownerNames.length - 1 && ','}
            {idx !== ownerNames.length - 1 && <br />}
          </React.Fragment>
        ))}
      </>
    ) : (
      <>{ownerNames.join(', ')}</>
    ),
  };

  if (ownerNames.length && isDesktopSize) {
    propertyNumbers.push(ownerNameElements);
  }

  if (propertyDetails.propertyType) {
    propertyNumbers.push({
      dataTestId: 'agx-propertyType',
      name: 'Property Type',
      detail: propertyDetails.propertyType,
    });
  }

  if (primaryParcel?.lotNumber) {
    propertyNumbers.push({
      dataTestId: 'agx-lotNumber',
      name: 'Lot',
      detail: primaryParcel?.lotNumber,
    });
  }

  if (primaryParcel?.planType && primaryParcel?.planNumber) {
    propertyNumbers.push({
      dataTestId: 'agx-planNumber',
      name: 'Plan',
      detail: `${primaryParcel?.planType}/${primaryParcel?.planNumber}`,
    });
  }

  if (propertyNumbers.length > 0) {
    detailSections['propertyNumbersDetails'] = propertyNumbers;
  }

  const propertySpecs = [];

  if (propertyDetails.numberOfBeds != null) {
    propertySpecs.push({
      name: 'Bed',
      icon: <Images.Bed />,
      detail: propertyDetails.numberOfBeds,
    });
  }

  if (propertyDetails.numberOfBathrooms != null) {
    propertySpecs.push({
      name: 'Bath',
      icon: <Images.Bath />,
      detail: propertyDetails.numberOfBathrooms,
    });
  }

  if (propertyDetails.numberOfCarSpaces != null) {
    propertySpecs.push({
      name: 'Car',
      icon: <Images.Car />,
      detail: propertyDetails.numberOfCarSpaces,
    });
  }

  const { landAreaDetails } = propertyDetails;

  const displayUnits = {
    [LandAreaType.SquareMetres]: 'm²',
    [LandAreaType.Hectares]: 'ha',
    [LandAreaType.Acres]: 'acre',
  };

  if (landAreaDetails?.totalLandArea && landAreaDetails?.totalLandAreaType) {
    const displayUnit =
      displayUnits[landAreaDetails.totalLandAreaType] ||
      displayUnits[LandAreaType.SquareMetres];

    propertySpecs.push({
      name: 'Size',
      icon: <Images.TotalLandArea />,
      detail: (
        <>
          {landAreaDetails.totalLandArea} {displayUnit}
        </>
      ),
    });
  }

  if (landAreaDetails?.internalArea) {
    propertySpecs.push({
      name: 'Internal',
      icon: <Images.InternalArea />,
      detail: (
        <>
          {landAreaDetails.internalArea}{' '}
          {displayUnits[LandAreaType.SquareMetres]}
        </>
      ),
    });
  }

  if (hasPool != null) {
    propertySpecs.push({
      name: 'Pool',
      icon: <Images.Pool />,
      detail: hasPool ? 'Yes' : 'No',
    });
  }

  if (propertySpecs.length > 0) {
    detailSections['propertySpecsDetails'] = propertySpecs;
  }

  const { propertySpecsDetails, propertyNumbersDetails } = detailSections;

  const mappedDetailPropertySpecsSections = (
    <AgxRow
      centered
      wrap={!isDesktopSize}
      extraClasses="mappedDetailsRowSpacing"
    >
      {propertySpecsDetails.map((section, i) => (
        <AgxRow
          key={section.name + i}
          centered
          extraClasses="mappedDetailsItem"
        >
          <AgxLabel small extraClasses="propertyImageLabel">
            {section.icon ? section.icon : section.name}
          </AgxLabel>
          <AgxBodyText small extraClasses="propertyImageDetail">
            {section.detail}
          </AgxBodyText>
        </AgxRow>
      ))}
    </AgxRow>
  );

  const mappedPropertyOwnersSection = !isDesktopSize ? (
    <AgxRow
      centered
      wrap={!isDesktopSize}
      spaceBetween={isDesktopSize}
      extraClasses="mappedDetailsRowSpacing propertyNumbers propertyOwners"
    >
      <AgxColumn
        key={ownerNameElements.name}
        extraClasses="mapDetailsColumnItem"
      >
        <AgxLabel small extraClasses="propertyImageLabel">
          {ownerNameElements.name}
        </AgxLabel>
        <AgxBodyText
          small
          extraClasses="propertyImageDetail"
          dataTestId={ownerNameElements.dataTestId}
        >
          {ownerNameElements.detail}
        </AgxBodyText>
      </AgxColumn>
    </AgxRow>
  ) : null;

  const mappedDetailPropertyNumbersSections = (
    <AgxRow
      centered
      spaceBetween={isDesktopSize}
      extraClasses="mappedDetailsRowSpacing propertyNumbers"
    >
      {propertyNumbersDetails.map((section, i) => (
        <AgxColumn key={section.name + i} extraClasses="mapDetailsColumnItem">
          <AgxLabel small extraClasses="propertyImageLabel">
            {section.name}
          </AgxLabel>
          <AgxBodyText
            small
            extraClasses="propertyImageDetail"
            dataTestId={section.dataTestId}
          >
            {section.detail}
          </AgxBodyText>
        </AgxColumn>
      ))}
    </AgxRow>
  );

  const [addressLine1, addressLine2] = cleanTwoLineAddress(address);

  if (isDesktopSize) {
    return (
      <AgxRow
        extraClasses={`imageCard ${currentTab === Tabs.LotPlan ? 'planCard' : ''}`}
        onClick={
          isDesktopSize
            ? async () => await onClick(allPropertyDetails)
            : undefined
        }
      >
        <div
          style={{
            backgroundImage: `url("${
              propertyImageUrls?.large ?? placeHolderImage
            }")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            aspectRatio: '2/1',
          }}
          className="imageCard-image"
        ></div>
        <AgxColumn fill extraClasses="propertyImageCard-Details">
          <AgxRow spaceBetween extraClasses="propertyImageCard-Details-TopRow">
            <AgxColumn extraClasses="propertyImageCard-Details-Title">
              <AgxHeader size={4}>{addressLine1},</AgxHeader>
              <AgxHeader size={4}>{addressLine2}</AgxHeader>
            </AgxColumn>

            <AgxButton
              id="confirmation-yesContinue"
              dataTestId={`testid-propertySearchYesBtn`}
              text="Continue"
              onClick={() =>
                !isDesktopSize ? onClick(allPropertyDetails) : undefined
              }
              extraClasses="hoverConfirmationBtn"
              hollow
              shrinkOnLargerDevices
              medium
            />
          </AgxRow>
          {mappedDetailPropertySpecsSections}
          {mappedDetailPropertyNumbersSections}
        </AgxColumn>
      </AgxRow>
    );
  } else {
    return (
      <AgxColumn
        extraClasses="imageCard"
        onClick={
          currentTab === Tabs.LotPlan && isDesktopSize
            ? async () => await onClick(allPropertyDetails)
            : undefined
        }
      >
        <div
          style={{
            backgroundImage: `url("${
              propertyImageUrls?.large ?? placeHolderImage
            }")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            aspectRatio: '2/1',
          }}
          className="imageCard-image"
        ></div>
        <AgxColumn fill extraClasses="imageCard-mobile-details">
          <AgxColumn extraClasses="imageCard-mobile-details-header">
            <AgxHeader size={4}>{addressLine1},</AgxHeader>
            <AgxHeader size={4}>{addressLine2}</AgxHeader>
          </AgxColumn>
          {mappedDetailPropertySpecsSections}
          {mappedPropertyOwnersSection}
          {mappedDetailPropertyNumbersSections}
        </AgxColumn>
        <AgxButton
          id="confirmation-yesContinue"
          dataTestId={`testid-propertySearchYesBtn`}
          text="Continue"
          onClick={() =>
            !isDesktopSize ? onClick(allPropertyDetails) : undefined
          }
          primary
          large
        />
      </AgxColumn>
    );
  }
};
